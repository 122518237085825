import {Fragment, useState} from "react";
import {useRouteMatch} from "react-router";
import {Link} from "react-router-dom";
import CoursesList from "../../Courses/CoursesList";
import ProfileCertificate from "../ProfileCertificate";
import CreateModal from "./CreateModal";
// import SuccessModal from "./SuccessModal";

const ProfileUserData = ({
                           lang,
                           user,
                           passedCourses,
                           activeCourses,
                           certificates
                         }) => {
  let match = useRouteMatch();
  const [isShowFullData, setShowFullData] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const PartUserData = () => {
    return (
      <Fragment>
        <p className="profile-user__label">{lang.PROFILE.PHONE_NUMBER}</p>
        <p className="profile-user__text">{user.phone_number}</p>
        <p className="profile-user__label">{lang.PROFILE.COUNTRY}</p>
        <p className="profile-user__text">{user.country}</p>
        <p className="profile-user__label">{lang.PROFILE.CITY}</p>
        <p className="profile-user__text">{user.city}</p>
        <p className="profile-user__label">{lang.PROFILE.PROJECT}</p>
        <p className="profile-user__text">{user.project}</p>
        <Link to={`${match.path}/edit`}>
          <button className="profile-user__btn">{lang.PROFILE.EDIT}</button>
        </Link>
      </Fragment>
    )
  }
  return (
    <div className="profile-wrap">
      <div className="profile-inner">
        <h1>{lang.PROFILE.TITLE}</h1>
        <div className="profile-user">
          <img className="profile-user__avatar"
               src={user.avatar_url ? user.avatar_url : 'assets/img/avatar-placeholder.svg'}
               alt="avatar"/>
          <p className="profile-user__label">{lang.AUTH.FIRST_NAME}</p>
          <p className="profile-user__text">{user.first_name}</p>
          <p className="profile-user__label">{lang.AUTH.LAST_NAME}</p>
          <p className="profile-user__text">{user.last_name}</p>
          <p className="profile-user__label">{lang.PROFILE.DESCRIPTION}</p>
          <p className="profile-user__text">{user.description}</p>
          <p className="profile-user__label">{lang.PROFILE.EMAIL}</p>
          <p className="profile-user__text">{user.email}</p>
          <div className="mobile-block">{isShowFullData ? <PartUserData/> :
            <a className="profile__link"
               onClick={() => setShowFullData(true)}>{lang.VIEW_ALL}</a>}
          </div>
          <div className="desktop-block"><PartUserData/></div>
        </div>
        {certificates.length > 0 ?
          <Fragment>
            <h2>{lang.PROFILE.CERTIFICATES}</h2>
            {certificates.map((certificate) => {
              return <ProfileCertificate key={certificate.id} lang={lang}
                                         certificate={certificate}/>
            })}
          </Fragment>
          : ''}
      </div>
      <div className="profile-courses">
        <div className="profile-courses__head">
          <h2>{lang.PROFILE.MY_COURSES}</h2>
          <button onClick={() => setIsCreateModalOpen(true)}
                  className="profile-user__btn profile-user__btn--head">{lang.PROFILE.LEGAL_ENTITY.OPEN_BUTTON_TITLE}</button>
        </div>
        {(passedCourses.length + activeCourses.length) === 0 ?
          <h3>{lang.PROFILE.NO_ACTIVE}</h3> :
          <Fragment>
            {activeCourses.length !== 0 ?
              <Fragment>
                <h3>{lang.PROFILE.ACTIVE}</h3>
                <CoursesList lang={lang} coursesList={activeCourses}/>
              </Fragment> : ''}
            {passedCourses.length !== 0 ?
              <Fragment>
                <h3>{lang.PROFILE.IS_PASSED}</h3>
                <CoursesList lang={lang} coursesList={passedCourses}/>
              </Fragment> : ''}
          </Fragment>
        }
      </div>
      {isCreateModalOpen &&
        <CreateModal lang={lang} onModalClose={setIsCreateModalOpen}/>}
    </div>
  )
}
export default ProfileUserData;
