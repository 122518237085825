import React, {useEffect, useRef, useState} from 'react';
import './Notifications.scss'
import {getNotifications} from "./Notification.service";
import NotificationItem from "../NotificationItem";
import clsx from "clsx";

const Notifications = ({lang, show, setUnreadNotificationsExists}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const firstLoading = useRef(true);

  useEffect(() => {
    if (!firstLoading.current) {
      firstLoading.current = false;
      return
    }

    getNotifications()
      .then(data => {
        const notifications = data.results;
        const unreadNotificationsExists = notifications.some(notification => notification.status === 'unread');

        setNotifications(notifications);
        setUnreadNotificationsExists(unreadNotificationsExists);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [])

  return (
    <div
      className={clsx("notifications__root", {notifications__root__visible: show})}>
      <p className="notifications__root__title">
        {lang.HEADER.NOTIFICATIONS}
      </p>

      {isLoading
        ? (
          <div className="notifications__root__text">
            {lang.HEADER.LOADING}
          </div>
        ) : notifications.length
          ? (
            <div className="notifications__root__list">
              {notifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </div>
          ) : (
            <div className="notifications__root__text">
              {lang.HEADER.NO_NOTIFICATIONS}
            </div>
          )}
    </div>
  )
}

export default Notifications;
