import {useState} from 'react';
import {withRouter} from 'react-router';
import {createTask} from '../../../Auth/Auth.service';
import './MessageModal.scss';

const MessageModal = ({
                        lang,
                        taskCategories,
                        changeMessageModal,
                        setSuccessMessage,
                        user,
                        history
                      }) => {

  const [messageType, setMessageType] = useState(taskCategories[0].key)
  const [message, setMessage] = useState('')

  const onSubmit = () => {
    const data = {
      task_category: messageType,
      page: history.location.pathname,
      task_text: message,
      user: user.id
    }
    createTask(data).then(res => {
      changeMessageModal(false)
      setSuccessMessage(true)
      setTimeout(() => {
        setSuccessMessage(false)
      }, 3000)
      console.log(data)
    })
  }
  return (
    <div className="message-modal">
      <div className="message-modal-bg"
           onClick={() => changeMessageModal(false)}></div>
      <div className="message-modal-wrap">
        <a className="message-modal-close"
           onClick={() => changeMessageModal(false)}></a>
        <h2>{lang.HEADER.MESSAGE}</h2>
        <select name="message-modal-select" id="message-modal-select"
                className="message-modal__select"
                value={messageType}
                onChange={
                  // eslint-disable-next-line no-restricted-globals
                  () => setMessageType(event.target.value)
                }>
          {taskCategories.map((item) => {
            return <option key={item.key} value={item.key}>{item.title}</option>
          })}
        </select>
        <textarea
          name="message-modal-textarea"
          id="message-modal-textarea"
          cols="30" rows="10"
          className="message-modal__textarea"
          placeholder={lang.HEADER.MESSAGE_PLACEHOLDER}
          value={message}
          onChange={(e) => setMessage(e.target.value)}></textarea>
        <button className="message-modal__btn btn--action"
                disabled={message === ''}
                onClick={onSubmit}>{lang.SEND}</button>
      </div>
    </div>
  )
}
export default withRouter(MessageModal);
