import '../components.scss';

const Checkbox = ({answer, change}) => {

  return (
    <div className="ui-wrap" onClick={() => change()}>
      <div className={'checkbox' + (answer.choice ? ' answer__active' : '')}
           style={answer.choice ? {backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/icons/check-white.svg'})`} : null}></div>
      {answer.label}
    </div>
  )
}

export default Checkbox;