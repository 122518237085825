import {Link} from 'react-router-dom';
import './HeaderDropdown.scss';

const HeaderDropdown = ({lang, logout, setIsDropdown}) => {
  return (
    <div className="dropdown">
      <Link className="dropdown__link" to="/profile"
            onClick={() => setIsDropdown(false)}>
        {lang.HEADER.PROFILE_LINK}
        <img src="/assets/icons/user.svg" alt="user"/>
      </Link>
      <Link className="dropdown__link" to="/profile/edit"
            onClick={() => setIsDropdown(false)}>
        {lang.HEADER.PROFILE_EDIT_LINK}
        <img src="/assets/icons/edit.svg" alt="edit"/>
      </Link>
      <Link className="dropdown__link" to="/auth" onClick={() => logout()}>
        {lang.HEADER.EXIT}
        <img src="/assets/icons/log-out.svg" alt="log-out"/>
      </Link>
    </div>
  )
}
export default HeaderDropdown;
