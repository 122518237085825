import HttpClient from '../services/http.client';

export const getCourse = async (id) => {
  return HttpClient.get(`/courses/${id}/`)
}
export const getCourseReviews = async (id, order, limit) => {
  return HttpClient.get(`/courses/${id}/reviews/?order_by=${order}&limit=${limit}`)
}
export const getCourseModules = async (id) => {
  return HttpClient.get(`/courses/${id}/modules/`)
}
export const sendPayment = async (payment) => {
  return HttpClient.post(`/payments/`, payment)
}
export const sendReview = async (review) => {
  return HttpClient.post(`/analytics/reviews/`, review)
}
export const apply = async (id, data) => {
  return HttpClient.post(`/courses/${id}/apply/`, data)
}

export const createOrder = async (courseId, amount) => {
  return HttpClient.post('/payments/orders/', {
    course_id: courseId,
    amount
  })
}
