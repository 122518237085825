import {Fragment, useEffect, useState} from "react";
import {
  apply,
  createOrder,
  getCourse,
  getCourseModules
} from "./Modules.service";
import ModulesList from "./ModulesList";
import WordEndingService from "../WordEnding";
import "./Modules.scss";
import LessonsHeader from "../Lessons/LessonsHeader";
import {Route, Switch, useRouteMatch, withRouter} from "react-router-dom";
import Lessons from "../Lessons";
import {Alert} from "../components";
import {toast} from "react-toastify";

const Modules = ({lang, user, history}) => {
  let match = useRouteMatch();

  const [course, setCourse] = useState({modules: []});
  const [orderRequesting, setOrderRequesting] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const courseId = match.params.id;

  const upperCase = (text) => {
    return text[0].toUpperCase() + text.slice(1);
  };

  const convertUrl = (text) => {
    if (text) {
      return text.replace("watch?v=", "embed/");
    }
  };

  const changeCoursePaymentModal = (open) => {
    if (course.is_passed) {
      history.push(
        `${history.location.pathname}/modules/${course.modules[0].id}/lessons/${course.modules[0].lessons[0].id}`
      );
    } else {
      if (course.price > 0 && !course.is_available) {
        createOrder(courseId, course.price)
          .then((res) => {
            setOrderRequesting(true);
            toast.info(lang.REDIRECT_TO_PAYMENT_SERVICE);

            window.location.href = res.order.checkout_url;
          })
          .catch((err) => {
            console.log(err)
            toast.error(lang.ERROR_TRY_LATER);
          })
          .finally(() => {
            setOrderRequesting(false);
          })
      } else {
        const lessons = [];
        course.modules.map((module) => {
          module.lessons.map((lesson) => {
            lessons.unshift(lesson);
          });
        });
        let lastAvailableLesson = lessons.find((lesson) => lesson.is_available);
        const findModule = course.modules.find((module) =>
          module.lessons.find((lesson) => lesson.id === lastAvailableLesson.id)
        );
        if (lastAvailableLesson.is_review) {
          lastAvailableLesson = lessons[lessons.length - 2];
        }
        history.push(
          `${history.location.pathname}/modules/${findModule.id}/lessons/${lastAvailableLesson.id}`
        );
      }
    }
  };

  const signUpForFree = () => {
    const data = {
      data: {
        price: course.price,
      },
      id: Number(courseId),
    };
    apply(courseId, data).then(() => {
      const newCourse = {...course};

      newCourse.is_available = !newCourse.manual_access;
      newCourse.is_waiting_enrollment = newCourse.manual_access;
      newCourse.modules[0].lessons[0].is_available = !newCourse.manual_access;

      setCourse(newCourse);
      setSuccess(!newCourse.manual_access);

      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    });
  };

  const Content = () => {
    return (
      <Fragment>
        <p className="modules__label">{lang.COURSES.AUTHORS}</p>
        <p className="modules__description modules-info__text">
          {course.authors}
        </p>
        <p className="modules__label">{lang.COURSES.TYPE}</p>
        <p className="modules__description modules-info__text">
          {course.course_type === "self_paced" ? lang.COURSES.SELF_PACED : ""}
        </p>
        <p className="modules__label">{lang.COURSES.CERTIFICATE.TITLE}</p>
        <p className="modules__description modules-info__text">
          {course.has_certificate
            ? upperCase(lang.COURSES.CERTIFICATE.HAVE)
            : upperCase(lang.COURSES.CERTIFICATE.WITHOUT)}
        </p>
        <p className="modules__label">{lang.COURSES.TIME}</p>
        <p className="modules__description modules-info__text">
          <span>{course.duration_in_hours} </span>
          {WordEndingService(course.duration_in_hours, lang.COURSES.HOURS)}
        </p>
        <p className="modules__label">
          {lang.COURSES.PROGRESS}{" "}
          <span className={course.is_passed ? "success" : ""}>
            {course.is_passed ? lang.COURSES.PASSED : `${course.progress} %`}
          </span>
        </p>
        <div className="modules__progress">
          <div
            className="modules__progress-percent"
            style={{width: course.progress + "%"}}
          />
        </div>
      </Fragment>
    );
  };

  useEffect(() => {
    getCourse(courseId).then((course) => {
      getCourseModules(courseId).then((modules) => {
        const newCourse = course;
        const review = {
          id: "review",
          title: lang.REVIEW.COMPLETION,
          is_available: course.progress === 100,
          is_passed: !!course.is_reviewed,
          is_review: !!course.is_reviewed,
        };
        if (modules.length > 0) {
          modules[modules.length - 1].lessons.push(review);
        }
        newCourse.modules = modules;
        setCourse(newCourse);
      });
    });
  }, [courseId]);

  return course.title ? (
    <Fragment>
      <Switch>
        <Route exact path={`${match.path}`}>
          <section className="modules">
            <LessonsHeader
              name={course.title}
              route={`/courses/`}
              setShow={() => {
              }}
              isShowMenu={false}
            />
            <div className="container">
              <div className="modules-wrap">
                <div className="modules-inner">
                  <div className="modules-header">
                    <h1>{course.title}</h1>
                    {course.video_url && (
                      <iframe
                        className="mobile-block"
                        src={convertUrl(course.video_url)}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    )}
                    <p className="modules__label">{lang.COURSES.ABOUT}</p>
                    <p
                      className="modules__description"
                      dangerouslySetInnerHTML={{__html: course.description}}
                    />
                    <div className="mobile-block modules-mobile">
                      <Content/>
                    </div>
                  </div>
                  <h2>{lang.COURSES.CONTENT}</h2>
                  {course.modules.length > 0 ? (
                    <ModulesList
                      lang={lang}
                      modulesList={course.modules}
                      courseId={match.params.id}
                    />
                  ) : (
                    <h2>Модули отсутствуют</h2>
                  )}

                  {/*<h2>*/}
                  {/*  {lang.COURSES.REVIEW} ({course.reviews_count})*/}
                  {/*</h2>*/}
                  {/*{course.modules.length > 0 && (*/}
                  {/*  <ReviewList*/}
                  {/*    lang={lang}*/}
                  {/*    course={course}*/}
                  {/*    courseId={courseId}*/}
                  {/*  />*/}
                  {/*)}*/}

                </div>

                <div className="modules-info">
                  <div className="desktop-block">
                    {course.video_url && (
                      <iframe
                        src={convertUrl(course.video_url)}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    )}
                    <Content/>
                  </div>
                  {(!course.is_available && course.price === 0)
                    ? (
                      <button className="free" onClick={signUpForFree}
                              disabled={course.is_waiting_enrollment}>
                        {course.is_waiting_enrollment
                          ? lang.COURSES.WAIT_FOR_ACCESS
                          : lang.COURSES.SIGN_UP_FREE}
                      </button>
                    ) : (
                      <button
                        onClick={() => changeCoursePaymentModal(true)}
                        disabled={(!course.is_available && course.price === 0)
                          || (!course.is_available && course.manual_access)
                          || orderRequesting}
                      >
                        {course.is_available
                          ? course.is_passed
                            ? lang.COURSES.RE_START
                            : course.manual_access
                              ? course.is_waiting_enrollment
                                ? lang.COURSES.WAIT_FOR_ACCESS
                                : lang.COURSES.NEXT
                              : lang.COURSES.NEXT
                          : course.price > 0
                            ? `Купить курс за ${course.price} ₸`
                            : lang.COURSES.NO_AVAILABLE}

                        {/*{course.is_available*/}
                        {/*  ? course.is_passed*/}
                        {/*    ? lang.COURSES.RE_START*/}
                        {/*    : lang.COURSES.NEXT*/}
                        {/*  : course.price > 0*/}
                        {/*    ? `Купить курс за ${course.price} ₸`*/}
                        {/*    : lang.COURSES.NO_AVAILABLE}*/}
                      </button>
                    )}
                  {isSuccess && (
                    <Alert text={lang.PAYMENT_CARD.SUCCESS.MESSAGE}/>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/*{isPaymentModal &&*/}
          {/*  <CoursePaymentModal*/}
          {/*    changeCoursePaymentModal={changeCoursePaymentModal}*/}
          {/*  />}*/}
        </Route>
        <Route exact path={`${match.path}/modules/:id/lessons/:id`}>
          <Lessons
            lang={lang}
            courseData={course}
            setCourse={setCourse}
            user={user}
          />
        </Route>
      </Switch>
    </Fragment>
  ) : null;
};

export default withRouter(Modules);
