import {ErrorMessage, Field, Form, Formik} from 'formik';
import {useState} from 'react';
import {register} from '../Auth.service';
import {checkEmail, RegisterValidation} from '../Validation';
import './Register.scss';

const Register = ({lang, setMessage, setInfoModal}) => {
  const [isPasswordType, setPasswordType] = useState(true);
  const [isRePasswordType, setRePasswordType] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const message = (email) => {
    return {
      title: lang.AUTH.CONFIRM_REGISTER.TITLE,
      description: lang.AUTH.CONFIRM_REGISTER.SEND_MAIL + ` "${email}". ` + lang.AUTH.CONFIRM_REGISTER.TEXT
    }
  }
  const text = () => {
    return {__html: lang.AUTH.CONFIRM_REGISTER.SERVICE};
  }
  const onSubmit = (values) => {
    if (values.password === values.re_password) {
      const {re_password, ...currentValues} = values;
      register(currentValues).then(res => {
        setMessage(message(values.email))
        localStorage.setItem('reg_email', values.email)
      }).catch(error => {
        setErrorMessage(error.response.data.email)
        setTimeout(() => {
          setErrorMessage(null)
        }, 3000)
      })
    }
  }
  return (
    <div className="auth-register">
      <Formik
        initialValues={{
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          re_password: ''
        }}
        validate={values => RegisterValidation(values, lang)}
        onSubmit={onSubmit}>
        {({values, isSubmitting}) => (
          <Form>
            <p className="label">Email</p>
            <Field type="email" name="email" placeholder="login@mail.com"/>
            {checkEmail(values.email) ?
              <img className="input__icon" src="/assets/icons/check-green.svg"
                   alt="check"/> : ''}
            <ErrorMessage className="error-message" name="email"
                          component="div"/>
            <p className="label">{lang.AUTH.FIRST_NAME}</p>
            <Field type="text" name="first_name"
                   placeholder={lang.AUTH.FIRST_NAME_PLACEHOLDER}/>
            <p className="label">{lang.AUTH.LAST_NAME}</p>
            <Field type="text" name="last_name"
                   placeholder={lang.AUTH.LAST_NAME_PLACEHOLDER}/>
            <p className="label">{lang.AUTH.PASSWORD}</p>
            <Field type={isPasswordType ? 'password' : 'text'} name="password"
                   placeholder={lang.AUTH.PASSWORD_PLACEHOLDER}/>
            <a onClick={() => setPasswordType(!isPasswordType)}>
              {isPasswordType ?
                <img className="input__icon" src="/assets/icons/eye.svg"
                     alt="eye"/> :
                <img className="input__icon" src="/assets/icons/eye-off.svg"
                     alt="eye"/>}
            </a>
            <ErrorMessage className="error-message" name="password"
                          component="div"/>
            <p className="label">{lang.AUTH.RE_PASSWORD}</p>
            <Field type={isRePasswordType ? 'password' : 'text'}
                   name="re_password"
                   placeholder={lang.AUTH.RE_PASSWORD_PLACEHOLDER}/>
            <a onClick={() => setRePasswordType(!isRePasswordType)}>
              {isRePasswordType ?
                <img className="input__icon" src="/assets/icons/eye.svg"
                     alt="eye"/> :
                <img className="input__icon" src="/assets/icons/eye-off.svg"
                     alt="eye-off"/>}
            </a>
            <ErrorMessage className="error-message" name="re_password"
                          component="div"/>
            <div className="error-message">{errorMessage}</div>
            <button className="auth__btn" type="submit"
                    onClick={() => setInfoModal(false)}
                    disabled={!values.email || !values.first_name || !values.last_name || !values.password || !values.re_password}>{lang.AUTH.CREATE_ACCOUNT}</button>
            <p className="auth__text" dangerouslySetInnerHTML={text()}/>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default Register;
