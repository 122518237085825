import {useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {login} from '../Auth.service';
import {checkEmail, LoginValidation} from '../Validation';
import './Login.scss';
import '../Auth.scss';

const Login = ({lang, setLoggedIn, history, setInfoModal}) => {
  const [isPasswordType, setPasswordType] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = (values, {setSubmitting}) => {
    login(values).then(res => {
      localStorage.setItem('ACCESS_TOKEN', res.access)
      localStorage.setItem('REFRESH_TOKEN', res.refresh)

      setLoggedIn(true)
      history.push('/')
    }).catch(error => {
      setErrorMessage(error.response.data.detail)

      setTimeout(() => {
        setErrorMessage(null)
      }, 3000)
    })

    setSubmitting(false);
  }
  return (
    <div className="auth-login">
      <Formik
        initialValues={{email: '', password: ''}}
        validate={values => LoginValidation(values, lang)}
        onSubmit={onSubmit}>
        {({values, isSubmitting}) => (
          <Form>
            <p className="label">{lang.AUTH.EMAIL}</p>
            <Field type="email" name="email" placeholder="login@mail.com"/>
            {checkEmail(values.email) ?
              <img className="input__icon" src="/assets/icons/check-green.svg"
                   alt="check"/> : ''}
            <ErrorMessage className="error-message" name="email"
                          component="div"/>
            <p className="label">{lang.AUTH.PASSWORD}</p>
            <Field type={isPasswordType ? 'password' : 'text'} name="password"
                   placeholder={lang.AUTH.PASSWORD_PLACEHOLDER}/>
            <a onClick={() => setPasswordType(!isPasswordType)}>
              {isPasswordType ?
                <img className="input__icon" src="/assets/icons/eye.svg"
                     alt="eye"/> :
                <img className="input__icon" src="/assets/icons/eye-off.svg"
                     alt="eye-off"/>}
            </a>
            <ErrorMessage className="error-message" name="password"
                          component="div"/>
            <div className="error-message">{errorMessage}</div>
            <Link className="auth__link"
                  to="/auth/forgot-password">{lang.AUTH.PASSWORD_LINK}</Link>
            <button className="auth__btn" type="submit"
                    onClick={() => setInfoModal(false)}
                    disabled={!values.email || !values.password || isSubmitting}>{lang.AUTH.LOGIN_ACCOUNT}</button>
          </Form>
        )}
      </Formik>
      {/* <hr />
      <Formik>
        <div className="auth__social-networks-wrap">
          <p className="label">Войти через социальные сети</p>
          <div className="auth__social-networks">
            <Link><img src="/assets/icons/google-icon.svg" alt="" /></Link>
            <Link><img src="/assets/icons/vk-icon.svg" alt="" /></Link>
            <Link><img src="/assets/icons/facebook-icon.svg" alt="" /></Link>
          </div>
        </div>
      </Formik> */}
    </div>
  );
}

export default withRouter(Login);
