import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {CreateEntityValidation} from '../../Auth/Validation';
import {createLegalEntity} from "../Profile.servise";
import {Fragment} from 'react/cjs/react.production.min';

const CreateModal = ({onModalClose, lang}) => {

  const [success, setSuccess] = useState(false)
  const [timer, setTimer] = useState(3)

  const onSubmit = (data) => {
    createLegalEntity(data).then(() => {
      setSuccess(true)
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, [])

  useEffect(() => {
    let interval = null
    if (success && timer) {
      interval = setInterval(() => setTimer(prev => prev - 1), 1000)
    } else if (success && !timer) {
      clearTimeout(interval)
      onModalClose(false)
    }
    return () => {
      if (interval) {
        clearTimeout(interval)
      }
    }
  }, [success, timer])
  const SuccessModal = () => {
    return (
      <div className="profile-user__create-modal create-modal">
        <div className="create-modal__body">
          <span onClick={() => onModalClose(false)}
                className="create-modal__close"/>
          <h2
            className="create-modal__title create-modal__title--success">{lang.PROFILE.LEGAL_ENTITY.SUCCESS_TITLE}</h2>
          <p className="create-modal__text">
            {lang.PROFILE.LEGAL_ENTITY.SUCCESS_TEXT}
          </p>
          <div className="create-modal__notification">
            Сообщение закроется через {timer} сек...
          </div>
        </div>
      </div>
    )
  }

  const CreationModal = () => {
    return (
      <div className="profile-user__create-modal create-modal">
        <div className="create-modal__body">
          <span onClick={() => onModalClose(false)}
                className="create-modal__close"/>
          <h2
            className="create-modal__title">{lang.PROFILE.LEGAL_ENTITY.CREATE_TITLE}</h2>
          <Formik
            initialValues={
              {
                name_of_company: "",
                bin: "",
                type_of_ownership: "",
                geolocation: "",
                team_email: "",
              }
            }
            validate={values => CreateEntityValidation(values, lang)}
            onSubmit={onSubmit}
          >
            {({values, isSubmitting}) => (
              <Form action="/" className="create-modal__form">
                {lang.PROFILE.LEGAL_ENTITY.FIELDS.map(field => {
                  return (
                    <Fragment key={field.TITLE}>
                      <p className="profile-user__label">{field.TITLE}</p>
                      <p
                        className="profile-user__label text__small">{field.SUBTITLE}</p>
                      <Field placeholder={field.PLACEHOLDER} type="text"
                             className="profile-user__input"
                             name={field.NAME}/>
                      <ErrorMessage className="error-message" name={field.NAME}
                                    component="div"/>
                    </Fragment>
                  )
                })}

                <button type={'submit'}
                        className="profile-user__btn profile-user__btn--head">{lang.PROFILE.LEGAL_ENTITY.SEND_BUTTON_TITLE}</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }

  return (
    <>
      {success ? <SuccessModal/> : <CreationModal/>}
    </>
  );
};

export default CreateModal;
