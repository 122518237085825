import ModuleCard from './ModuleCard';
import './ModulesList.scss';

const ModulesList = ({modulesList, lang, courseId}) => {
  return (
    <div className="modules-list">
      {modulesList.map((moduleItem) => {
        return <ModuleCard key={moduleItem.id}
                           lastItem={modulesList[modulesList.length - 1]}
                           moduleItem={moduleItem}
                           courseId={courseId} lang={lang}/>
      })}
    </div>
  )
}

export default ModulesList;
