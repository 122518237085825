import {Link} from 'react-router-dom';
import {Icon} from '../../../components';
import './HeaderMenu.scss';

const HeaderMenu = ({
                      lang,
                      setLang,
                      logout,
                      changeMessageModal,
                      changeMenu,
                      user
                    }) => {
  const showMessageModal = () => {
    changeMenu(false)
    changeMessageModal(true)
  }
  return (
    <div className="header-menu">
      <button className="header-menu-bg"
              onClick={() => changeMenu(false)}></button>
      {user ? (
        <div className="header-menu-wrap">
          <div className="header-menu-logos">
            <img src="/assets/img/logo-jas.svg" alt="logo" className="logo"/>
            {/* <img src="/assets/img/logo.svg" alt="logo" className="logo" /> */}
          </div>
          <div className="header-menu-inner">
            <img
              src={user.avatar_url ? user.avatar_url : '/assets/img/avatar-placeholder.svg'}
              alt="avatar"
              className="header-menu__avatar"/>
            <p
              className="header-menu__name">{user.first_name} {user.last_name}</p>
          </div>
          <Link to="/profile" className="header-menu__link"
                onClick={() => changeMenu(false)}>
            {lang.HEADER.LINKS.MY_PROFILE}
            <img src="/assets/icons/user.svg" alt="user"/>
          </Link>
          <Link to="/courses" className="header-menu__link last"
                onClick={() => changeMenu(false)}>
            {lang.HEADER.LINKS.CATALOG}
            <Icon name="catalog"/>
          </Link>
          <a className="header-menu__link" style={{margin: 0}}
             onClick={showMessageModal}>
            {lang.HEADER.MESSAGE}
            <Icon name="message"/>
          </a>
          <div className="header-menu__lang">
            {lang.HEADER.LANG}
            <select name="lang" id="lang" className="lang"
                    onChange={
                      // eslint-disable-next-line no-restricted-globals
                      () => setLang(event.target.value)
                    }>
              <option value="ru">RU</option>
              <option value="en">EN</option>
              <option value="kz">KZ</option>
            </select>
          </div>
          <Link to="/auth" onClick={() => changeMenu(false)}>
            <button onClick={() => logout()}
                    className="header-menu__logout">{lang.HEADER.LOGOUT}</button>
          </Link>
        </div>
      ) : ''}
    </div>
  )
}
export default HeaderMenu;
