import ru from './ru.json';
import en from './en.json';
import kz from './kz.json';

const TranslateService = (lang) => {
  if (lang === 'ru') {
    return ru;
  } else if (lang === 'en') {
    return en;
  } else if (lang === 'kz') {
    return kz;
  }
}

export default TranslateService;