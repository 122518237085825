import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {SENTRY_DSN} from "./shared/constants";
import App from './App';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);
