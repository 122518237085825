import {Field, Form, Formik} from 'formik';
import {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {LoginValidation} from '../../Auth/Validation';
import '../Profile.scss';
import {addFile, editProfile} from '../Profile.servise';

const ProfileEdit = ({lang, user, setUser, history}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const {
    first_name,
    last_name,
    description,
    email,
    avatar_url,
    phone_number,
    country,
    project,
    city
  } = user;
  const onSubmit = (values) => {
    values.avatar_url = avatar
    editProfile(values).then((userData) => {
      setUser(userData)
      history.push('/profile')
    }).catch(error => {
      setErrorMessage(error.response.data.email)
      setTimeout(() => {
        setErrorMessage(null)
      }, 3000)
    })
  }
  const changeFile = (event) => {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('file', file)
    addFile(data).then((avatar) => {
      setAvatar(avatar.file)
    }).catch((error) => {
      console.log(error)
    })
  }

  return (
    <div className="profile-inner profile-edit">
      <h1>{lang.PROFILE.EDIT}</h1>
      <div className="profile-user">
        <Formik
          initialValues={{
            avatar_url,
            first_name,
            last_name,
            description,
            email,
            phone_number,
            country,
            city,
            project
          }}
          validate={values => LoginValidation(values, lang)}
          onSubmit={onSubmit}>
          {({values, isSubmitting}) => (
            <Form>
              <label htmlFor="avatar-file">
                <div className="profile-edit-img">
                  <img className="profile-edit-img__icon"
                       src="/assets/icons/camera.svg" alt="camera"/>
                  <img className="profile-user__avatar"
                       src={avatar ? avatar : (avatar_url ? avatar_url : '/assets/img/avatar-placeholder.svg')}
                       alt="avatar"/>
                </div>
              </label>
              <input className="file" type="file" id="avatar-file"
                     onChange={(e) => changeFile(e)}/>
              <p className="profile-user__label">{lang.AUTH.FIRST_NAME}</p>
              <Field type="text" className="profile-user__input"
                     name="first_name"/>
              <p className="profile-user__label">{lang.AUTH.LAST_NAME}</p>
              <Field type="text" className="profile-user__input"
                     name="last_name"/>
              <p className="profile-user__label">{lang.PROFILE.DESCRIPTION}</p>
              <Field type="text" className="profile-user__input"
                     name="description"/>
              <p className="profile-user__label">{lang.PROFILE.EMAIL}</p>
              <Field type="email" className="profile-user__input" name="email"/>
              <div className="error-message">{errorMessage}</div>
              <p className="profile-user__label">{lang.PROFILE.PHONE_NUMBER}</p>
              <Field type="number" className="profile-user__input"
                     name="phone_number"/>
              <p className="profile-user__label">{lang.PROFILE.COUNTRY}</p>
              <Field type="text" className="profile-user__input"
                     name="country"/>
              <p className="profile-user__label">{lang.PROFILE.CITY}</p>
              <Field type="text" className="profile-user__input" name="city"/>
              <p className="profile-user__label">{lang.PROFILE.PROJECT}</p>
              <Field type="text" className="profile-user__input"
                     name="project"/>
              <button type="submit"
                      className="profile-user__btn">{lang.SAVE}</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
export default withRouter(ProfileEdit);
