export const stars = [
  {
    id: 1,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 2,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 3,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 4,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 5,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 6,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 7,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 8,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 9,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 10,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  }
]
export const stars2 = [
  {
    id: 1,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 2,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 3,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 4,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 5,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 6,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 7,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 8,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 9,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  },
  {
    id: 10,
    isColor: false,
    isFixed: false,
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3263 3.52396C11.6486 2.82535 12.6415 2.82535 12.9637 3.52396L14.9861 7.90847C15.1174 8.19319 15.3872 8.38924 15.6986 8.42616L20.4935 8.99466C21.2575 9.08525 21.5643 10.0296 20.9995 10.5519L17.4545 13.8302C17.2243 14.0431 17.1212 14.3603 17.1823 14.6678L18.1233 19.4037C18.2733 20.1583 17.47 20.7419 16.7987 20.3661L12.5854 18.0077C12.3118 17.8546 11.9782 17.8546 11.7046 18.0077L7.49134 20.3661C6.82001 20.7419 6.01673 20.1583 6.16667 19.4037L7.10768 14.6678C7.16879 14.3603 7.06572 14.0431 6.83552 13.8302L3.29055 10.5519C2.72571 10.0296 3.03253 9.08525 3.79653 8.99466L8.59139 8.42616C8.90277 8.38924 9.1726 8.19319 9.30393 7.90847L11.3263 3.52396Z" fill="#878CA3"/>
              </svg>
              `
  }
]