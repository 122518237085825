import {useCallback, useEffect, useState} from "react";
import {Link, useRouteMatch, withRouter} from "react-router-dom";
import {
  getLessonReview,
  getLessons,
  sendLessonReview,
  sendTest,
  subscribeForNotification,
  unsubscribeFromNotification,
  uploadAttachmentAPI,
} from "./Lessons.service";
import Frame from "./Frame";
import LessonsNavigation from "./LessonsNavigation";
import LessonDirection from "./LessonDirection";
import LessonsTest from "./LessonsTest/LessonsTest";
import LessonPresentation from "./LessonPresentation";
import LessonsHeader from "./LessonsHeader";
import CourseReview from "../Modules/CourseReview/CourseReview";
import LessonReview from "./LessonReview";
import "./Lessons.scss";

const Lessons = ({lang, courseData, setCourse, history, user}) => {
  let match = useRouteMatch();

  const [lesson, setLesson] = useState(null);
  const [newLesson, setNewLesson] = useState(null);

  const isMobileDeviceWidth = window.innerWidth < 768;

  const [answers, setAnswers] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedModule, setSelectedModule] = useState({lessons: []});
  const [openPresentation, setOpenPresentation] = useState(false);
  const [modules, setModules] = useState(courseData.modules);
  const [isLiked, setLiked] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileInputValue, setFileInputValue] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [attachmentData, setAttachmentData] = useState({
    id: null,
    file: "",
  });
  const [uploadStatus, setUploadStatus] = useState("");

  const onUploadProgress = (progressEvent) => {
    const {loaded, total} = progressEvent;

    const percent = Math.floor((loaded * 100) / total);

    if (percent <= 100) {
      setUploadProgress(percent);
    }
  };

  const uploadAttachment = useCallback(async () => {
    setUploadStatus(lang.QUESTION.UPLOADING);

    const form = new FormData();

    form.append("file", attachment);

    const attachmentData = await uploadAttachmentAPI(form, onUploadProgress);

    setAttachmentData(attachmentData);
    setUploadProgress(0);
    setUploadStatus(lang.QUESTION.UPLOADED);
  }, [attachment]);

  const handleChangeAttachment = (e) => {
    setFileInputValue("");
    setAttachment(e.target.files[0]);
  };

  const removeAttachment = () => {
    setUploadStatus("");
    setFileInputValue("");
    setAttachment(null);
    setAttachmentData({id: null, file: ""});
  };

  const pathnameArray = history.location.pathname.split("/");
  const courseId = pathnameArray[2];
  const moduleId = pathnameArray[4];
  const lessonId = match.params.id;

  useEffect(() => {
    if (lessonId === "review") {
      const reviewLesson = {
        description: null,
        duration_in_hours: 0,
        id: "13",
        frame: null,
        quiz: null,
        presentation: [],
        title: lang.REVIEW.COMPLETE,
      };

      setLesson(reviewLesson);

      const module = courseData.modules.find(
        (moduleItem) => moduleItem.id === Number(moduleId)
      );

      module && setSelectedModule(module);
    } else {
      getLessons(courseId, moduleId, lessonId).then((lesson) => {
        setLesson(lesson);

        const module = courseData.modules.find(
          (moduleItem) => moduleItem.id === Number(moduleId)
        );

        module && setSelectedModule(module);
      });
      getLessonReview(courseId, moduleId, lessonId).then((res) => {
        setLiked(res.is_liked);
      });
    }
    return () => {
      setLesson(null);
      setNewLesson(null);
      setAnswers([]);
    };
  }, [lessonId]);

  useEffect(() => {
    if (lesson) {
      checkStatus();
    }
  }, [newLesson, lesson, selectedModule]);

  useEffect(() => {
    if (isMobileDeviceWidth) {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (attachment) {
      uploadAttachment();
    }
  }, [attachment, uploadAttachment]);

  useEffect(() => {
    if (lesson?.quiz && !lesson?.quiz?.is_solved) {
      subscribeForNotification(courseId, moduleId, lessonId);
    }
  }, [lesson])

  const description = () => {
    return {__html: lesson.description};
  };

  const changeStatus = () => {
    if (selectedModule.lessons.length > 0) {
      let isNextModule = false;
      const idxModule = courseData.modules.findIndex(
        (module) => module.id === selectedModule.id
      );
      const idx = selectedModule.lessons.findIndex(
        (item) => item.id === lesson.id
      );
      if (selectedModule.lessons.length !== idx + 1) {
        selectedModule.lessons[idx + 1].is_available = true;
      } else {
        isNextModule = true;
      }
      const newModules = courseData.modules.map((module, index) => {
        module.lessons.map((item) => {
          if (item.id === lesson.id) {
            if (item.is_available) {
              item.is_passed = true;
            }
          }
          return item;
        });
        if (isNextModule) {
          if (index === idxModule + 1) {
            if (module.lessons[0]) {
              module.lessons[0].is_available = true;
            }
          }
        }
        return module;
      });
      setModules(newModules);
    }
  };

  const checkStatus = () => {
    if (!lesson.quiz) {
      changeStatus();
    } else {
      const percent = 100 / lesson.quiz.criterion.length;
      const correctCriterion = lesson.quiz.criterion.filter(
        (criteria) => criteria.is_correct
      ).length;
      if (lesson.quiz.acceptance <= percent * correctCriterion) {
        changeStatus();
      }
    }
  };

  const changeSingleChoiceAnswer = (answer, answers) => {
    if (!lesson.quiz.is_solved) {
      answers = answers.map((m) => {
        m.choice = m.key === answer.key;
        return m;
      });
      setAnswers((oldArray) =>
        [...oldArray, answer].filter((a) => a.choice === true)
      );
    }
  };

  const changeMultiChoiceAnswer = (answer) => {
    if (!lesson.quiz.is_solved) {
      answer.choice = !answer.choice;
      setAnswers((oldArray) =>
        [...oldArray, answer].filter((a) => a.choice === true)
      );
    }
  };

  const changeOpenEndedAnswer = (answerValue, variant) => {
    if (!lesson.quiz.is_solved) {
      variant.answer = answerValue;
      setAnswers((oldArray) =>
        [...oldArray, variant].filter((a, b) => a.id === b.id)
      );
    }
  };

  const changePresentationModal = (isOpen) => {
    setOpenPresentation(isOpen);
    isOpen
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  };

  const changeLessonReview = (isLiked) => {
    const data = {
      is_liked: isLiked,
    };
    sendLessonReview(courseId, moduleId, lessonId, data).then();
  };

  const onSubmit = (type) => {
    if (type === "re_start") {
      const newCriterion = lesson.quiz.criterion.map((criteria) => {
        criteria.variants.map((variant) => {
          if (criteria.type === "open_ended") {
            variant.answer = "";
          } else {
            variant.choice = false;
          }
          return variant;
        });
        return criteria;
      });

      lesson.quiz.is_solved = false;
      lesson.quiz.criterion = newCriterion;

      const newLesson = lesson;

      setLesson(newLesson);
      setNewLesson(newLesson);
    } else if (type === "next") {
      const lessons = [];

      courseData.modules.map((module) => {
        module.lessons.map((lesson) => {
          lessons.push(lesson);
        });
      });

      const nextLessonIdx = lessons.findIndex((l) => l.id === lesson.id) + 1;
      const nextModuleIdx =
        modules.findIndex((l) => l.id === Number(moduleId)) + 1;

      if (lessons[nextLessonIdx]) {
        if (
          selectedModule.lessons[selectedModule.lessons.length - 1].id === lesson.id
        ) {
          history.push(
            `/courses/${courseId}/modules/${modules[nextModuleIdx].id}/lessons/${lessons[nextLessonIdx].id}`
          );
        } else {
          history.push(
            `/courses/${courseId}/modules/${moduleId}/lessons/${lessons[nextLessonIdx].id}`
          );
        }
      }
    } else {
      if (attachmentData.id) {
        lesson.quiz.criterion[0]["attachment"] = attachmentData.id;
      }

      sendTest(courseId, moduleId, lessonId, lesson.quiz).then((res) => {
        unsubscribeFromNotification(courseId, moduleId, lessonId);

        setNewLesson(res)
        if (res.is_passed) {
          changeStatus()
        }
      })
    }
  };

  return lesson && selectedModule.lessons.length > 0 ? (
    <section className="lesson">
      <LessonsHeader
        name={lesson.title}
        route={`/courses/${courseData.id}`}
        show={show}
        setShow={setShow}
        isShowMenu={true}
      />
      <LessonsNavigation
        lang={lang}
        courseId={courseData.id}
        modules={modules}
        currentLesson={lesson}
        isMobileDeviceWidth={isMobileDeviceWidth}
        show={show}
        setShow={setShow}
      />
      <div className={`container ${!show ? "lesson-desktop-translate" : ""}`}>
        <div className="lesson-wrap">
          <div className="lesson-content">
            <div className="lesson-inner desktop-block">
              <span>
                {courseData.title} • {selectedModule.title}
              </span>
            </div>
            <h2 className="lesson__title desktop-flex a-i-c">
              <Link
                to={`/courses/${courseData.id}`}
                className="lesson__link"
              ></Link>
              {lesson.title}
            </h2>
            {lesson.frame && lesson.frame.frame_steps.length > 0 ? (
              <Frame
                lang={lang}
                frame_steps={lesson.frame.frame_steps}
                user={user}
              />
            ) : (
              ""
            )}
            {lesson.description ? (
              <div className="lesson-content-wrap">
                {lesson.presentation && (
                  <a
                    href={lesson.presentation}
                    className="lesson-content__btn btn--action"
                    rel="noopener noreferrer"
                  >
                    {lang.COURSES.OPEN_PRESENTATION}
                  </a>
                )}
                <div dangerouslySetInnerHTML={description()}/>
              </div>
            ) : (
              ""
            )}
            {lessonId === "review" ? (
              <CourseReview lang={lang} course={courseData} setShow={setShow}/>
              // <Completion lang={lang} course={courseData}/>
            ) : (
              ""
            )}
            {lesson.quiz ? (
              <LessonsTest
                lang={lang}
                lesson={newLesson ? newLesson : lesson}
                attachment={attachment}
                uploadProgress={uploadProgress}
                uploadStatus={uploadStatus}
                fileInputValue={fileInputValue}
                changeSingleChoiceAnswer={changeSingleChoiceAnswer}
                changeMultiChoiceAnswer={changeMultiChoiceAnswer}
                changeOpenEndedAnswer={changeOpenEndedAnswer}
                onChangeAttachment={handleChangeAttachment}
                onRemoveAttachment={removeAttachment}
                onSubmit={onSubmit}
              />
            ) : (
              ""
            )}
            {lessonId === "review" ? (
              ""
            ) : (
              <LessonReview
                lang={lang}
                changeLessonReview={changeLessonReview}
                isLiked={isLiked}
              />
            )}
          </div>
        </div>
      </div>
      <LessonDirection
        lang={lang}
        selectedModule={selectedModule}
        modules={courseData.modules}
        selectedLesson={lesson}
      />
      {openPresentation ? (
        <LessonPresentation
          presentation={lesson.presentation}
          changePresentationModal={changePresentationModal}
        />
      ) : null}
    </section>
  ) : (
    ""
  );
};

export default withRouter(Lessons);
