import {useRef, useState} from 'react';
import {withRouter} from 'react-router';
import {Alert} from '../../components';
import './CourseReview.scss';

const CertificateModule = ({lang, certificate, course, history}) => {

  const inputRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(null);
  const copyLink = () => {
    const textField = document.createElement('textarea')
    textField.innerText = certificate.url
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000)
  };
  return (
    <div className="certificate-modal">
      <div className="certificate-modal-wrap">
        <div className="certificate-modal-wrap__title__buttonClosed">
          <h2
            className="certificate-modal-wrap__title">{lang.REVIEW.YOUR_CERTIFICATE}</h2>
        </div>
        <h2
          className="certificate-modal-wrap__title-media">{lang.REVIEW.YOUR_CERTIFICATE}</h2>
        <div className="certificate-modal-wrap-logoLink">
          <div className="certificate-modal-wrap-logoLink__logo">
            <img src="/assets/img/Ico.png" alt=""/>
          </div>
          <div className="certificate-modal-wrap-logoLink__link">
            <div className="certificate-modal-wrap-logoLink__link__subtitle">
              <p>{course.title}</p>
            </div>
            <div className="certificate-modal-wrap-logoLink__link__link">
              <input type="text" disabled={true} ref={inputRef}
                     value={certificate.url}/>
              <img src="/assets/img/copy.svg" alt=""/>
              <button onClick={copyLink}>{lang.COPY}</button>
            </div>
          </div>
        </div>
        <hr/>
        <div className="certificate-modal-wrap-buttons">
          <a href={certificate.url} target="_blank">
            <button
              className="certificate-modal-wrap-buttons__button__seeCertificate">{lang.REVIEW.SEE_CERTIFICATE}</button>
          </a>
          <button
            className="certificate-modal-wrap-buttons__button__seeMoreCourse"
            onClick={() => history.push('/courses')}>{lang.REVIEW.SEE_COURSES}</button>
        </div>
      </div>
      {copySuccess ? <Alert text={lang.COPYED}/> : ''}
    </div>

  );
}


export default withRouter(CertificateModule);
