import {useRef, useState} from 'react';
import {Alert} from '../../components';
import './ProfileCertificate.scss';

const ProfileCertificate = ({certificate, lang}) => {
  const inputRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(null);
  const copyLink = () => {
    const textField = document.createElement('textarea')
    textField.innerText = certificate.url
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000)
  };
  return (
    <div className="profile-certificate">
      <div className="certificate-modal-wrap-logoLink">
        <div className="certificate-modal-wrap-logoLink__logo">
          <img src="/assets/img/cert_icon.png" alt=""/>
        </div>
        <div className="certificate-modal-wrap-logoLink__link">
          <div className="certificate-modal-wrap-logoLink__link__subtitle">
            <p>{certificate.course.title}</p>
          </div>
          <div className="certificate-modal-wrap-logoLink__link__link">
            <input type="text" disabled={true} ref={inputRef}
                   value={certificate.url}/>
            <button onClick={copyLink}><img src="/assets/img/copy.svg" alt=""/>
            </button>
          </div>
        </div>
      </div>
      {copySuccess ? <Alert text={lang.COPYED}/> : ''}

    </div>
  )
}
export default ProfileCertificate;
