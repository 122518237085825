import './LessonsHeader.scss';
import arrow from '../../assets/icons/arrow-grey.svg'
import {Link} from 'react-router-dom';

const LessonsHeader = ({name, route, show, setShow, isShowMenu}) => {
  return (
    <div className="lessons-header mobile-flex">
      <div className="d-flex a-i-c">
        <Link to={route}>
          <img src={arrow} alt=""/>
        </Link>
        <p className="name">{name}</p>
      </div>
      {isShowMenu ?
        <button className="lessons-header__btn" onClick={() => setShow(!show)}>
          <img src="/assets/icons/nav-menu.svg"
               alt=""/></button> : ''}
    </div>
  )
}
export default LessonsHeader;
