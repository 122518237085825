import CourseProgressCircle from "./CourseProgressCircle/CourseProgressCircle";
import "./CourseCard.scss";
import WordEndingService from "../../../WordEnding";


const CourseCard = ({ courseItem, lang }) => {
  const {
    is_available,
    title,
    short_description,
    price,
    course_type,
    authors,
    has_certificate,
    duration_in_hours,
    rating,
    reviews_count,
    manual_access
  } = courseItem;

  const {
    COURSES: {
      SELF_PACED,
      CERTIFICATE: { WITH, WITHOUT },
      QUEST,
      ENROLLED,
      FREE,
      HOURS,
      REVIEWS,
    },
    CLOSED
  } = lang;

  return (
    <div className="course-card">
      <div className="course-card-icon">
        {/* <img src="/assets/img/logo-jas.svg" alt="star"/> */}
        <img src="/assets/icons/book.svg" alt="book"/>
      </div>
      <div className="course-card-content">
        <h3
          className={`course-card-content__title ${is_available ? "" : "disabled--content"
            }`}
        >
          {title}
        </h3>
        <div
          className={`course-card-content__text desktop-block ${is_available ? "" : "disabled--content"
            }`}
        >
          {short_description}
        </div>
        <div
          className={`course-card-content__text mobile-block ${is_available ? "" : "disabled--content"
            }`}
        >
          {short_description.substring(0, 77)} ...
        </div>
        <div
          className={`course-card-content__text ${is_available && price === 0 ? "border-none" : ""
            }`}
        >
          {course_type === "self_paced"
            ? SELF_PACED + " "
            : course_type === "quest"
              ? QUEST + " "
              : ""}
          {has_certificate ? WITH : WITHOUT} • {authors ? `${authors} •` : ""}{" "}
          {duration_in_hours} {WordEndingService(duration_in_hours, HOURS)}
        </div>
        <div className="starContainer">
          <img src="/assets/icons/star.svg" alt="" style={{ marginRight: 6 }} />
          <span className="course-card-content__rating">{rating}</span>
          <span className="course-card-content__review">
            ({reviews_count} {WordEndingService(reviews_count, REVIEWS)})
          </span>
        </div>
        {is_available ? (
          <div className="course-card-content__price bg-blue">{ENROLLED}</div>
        ) : manual_access ? (
          <div className="course-card-content__price">
            {CLOSED}
          </div>
        ) : (
          <div
            className={`course-card-content__price ${price === 0 ? "bg-green" : ""
              }`}
          >
            {price === 0 ? FREE : `${price} ₸`}
          </div>
        )}
      </div>
      <CourseProgressCircle courseItem={courseItem} />
    </div>
  );
};

export default CourseCard;
