import {useEffect} from "react";
import {useRouteMatch, withRouter} from "react-router";
import {Link} from "react-router-dom";
import {activation} from "../Auth.service";

const Activation = ({lang}) => {
  let match = useRouteMatch();

  useEffect(() => {
    const data = {
      uid: match.params.uid,
      token: match.params.token
    }
    activation(data).then((res) => {
      console.log(res)
    }).catch(error => {
      console.log(error)
    })
  }, [])

  return (
    <div style={{textAlign: 'center'}}>
      <h2>{lang.ACTIVATION.TITLE}</h2>

      <p className="auth__text">{lang.ACTIVATION.TEXT}</p>

      <Link to='/auth'>
        <button className="auth__btn">{lang.AUTH.LOGIN}</button>
      </Link>
    </div>
  )
}
export default withRouter(Activation);