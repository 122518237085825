import React from 'react';
import './NotificationItem.scss'
import {Link} from "react-router-dom";
import {markNotificationAsRead} from "./NotificationItem.service";

const NotificationItem = ({notification}) => {
  const url = new URL(notification.link);

  const markAsRead = () => {
    markNotificationAsRead(notification.id)
  }

  return (
    <Link
      to={url.pathname}
      style={{color: "#5C6075"}}
      onClick={markAsRead}
    >
      <div className="notification">
        <p
          className="notification__text"
          dangerouslySetInnerHTML={{__html: notification.text}}
        />
      </div>
    </Link>
  )
}

export default NotificationItem;
