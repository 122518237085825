const checkEmail = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
}
const checkCardInput = (value, length) => {
  let newValue = value.replace(/[^1-9]/gi, '');
  return newValue.length === length
}

const LoginValidation = (values, lang) => {
  const errors = {};
  if (!values.email) {
    errors.email = lang.ERROR_MESSAGE.REQUIRED;
  } else if (
    !checkEmail(values.email)
  ) {
    errors.email = lang.ERROR_MESSAGE.INVALID.EMAIL;
  }
  return errors;
}

const CreateEntityValidation = (values, lang) => {
  const errors = {}
  if (!values.name_of_company) {
    errors.name_of_company = lang.ERROR_MESSAGE.REQUIRED
  }
  if (!values.bin) {
    errors.bin = lang.ERROR_MESSAGE.REQUIRED
  }
  if (!values.team_email) {
    errors.team_email = lang.ERROR_MESSAGE.REQUIRED
  }
  if (!values.type_of_ownership) {
    errors.type_of_ownership = lang.ERROR_MESSAGE.REQUIRED
  }
  if (!values.geolocation) {
    errors.geolocation = lang.ERROR_MESSAGE.REQUIRED
  }
  if (values.bin.length < 12) {
    errors.bin = lang.ERROR_MESSAGE.BIN
  }
  // if (values.name_of_company.length < 30) {
  //   errors.name_of_company = 'Нзвание должно быть не более 30'
  // }
  // if (values.bin.length < 12) {
  //   errors.bin = 'БИН должен быть не более 12 символов'
  // }
  // if (values.type_of_ownership.length < 30) {
  //   errors.bin = 'БИН должен быть не более 30 символов'
  // }
  // if (values.geolocation.length > 40) {
  //   errors.bin = 'БИН должен быть не более 30 символов'
  // }
  // else {
  //     values.team_email.split(',').forEach(email => {
  //         if (!checkEmail(email.trim())) {
  //             errors.team_email = lang.ERROR_MESSAGE.INVALID.EMAIL
  //         }
  //     })
  // }

  return errors
}

const RegisterValidation = (values, lang) => {
  const errors = {};
  if (!values.email) {
    errors.email = lang.ERROR_MESSAGE.REQUIRED;
  } else if (
    !checkEmail(values.email)
  ) {
    errors.email = lang.ERROR_MESSAGE.INVALID.EMAIL;
  } else if (values.password !== values.re_password) {
    errors.re_password = lang.ERROR_MESSAGE.INVALID.RE_PASSWORD
  }
  if (values.password.length < 6) {
    errors.password = lang.AUTH.RE_PASSWORD_PLACEHOLDER
  }
  if (values.re_password.length < 6) {
    errors.re_password = lang.AUTH.RE_PASSWORD_PLACEHOLDER
  }
  return errors;
}
const ResetValidation = (values, lang) => {
  const errors = {};
  if (values.new_password !== values.re_new_password) {
    errors.re_new_password = lang.ERROR_MESSAGE.INVALID.RE_PASSWORD
  }
  if (values.new_password.length < 6) {
    errors.new_password = lang.AUTH.RE_PASSWORD_PLACEHOLDER
  }
  if (values.re_new_password.length < 6) {
    errors.re_new_password = lang.AUTH.RE_PASSWORD_PLACEHOLDER
  }
  return errors;
}
const CoursePaymentValidation = (values, lang) => {
  const errors = {};
  if (!values.cardNumber) {
    errors.cardNumber = lang.PAYMENT_CARD.VALIDATION.CARD;
  } else if (values.cardNumber.replace(/[^0-9]/gi, '').length !== 16) {
    errors.cardNumber = lang.PAYMENT_CARD.VALIDATION.CARD;
  }
  if (!values.cardDate) {
    errors.cardDate = lang.PAYMENT_CARD.VALIDATION.DATE;
  }
  if (!values.cvv) {
    errors.cvv = lang.PAYMENT_CARD.VALIDATION.CVV;
  }
  if (!values.name) {
    errors.name = lang.PAYMENT_CARD.VALIDATION.NAME;
  }
  return errors;
}
export {
  LoginValidation,
  RegisterValidation,
  checkEmail,
  CoursePaymentValidation,
  checkCardInput,
  ResetValidation,
  CreateEntityValidation
};