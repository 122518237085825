import {useState} from 'react';
import {Icon} from '../../components';
import './LessonReview.scss';

const LessonReview = ({lang, changeLessonReview, isLiked}) => {
  const [like, setLike] = useState(isLiked)


  const changeLike = (is_liked) => {
    setLike(is_liked)
    changeLessonReview(is_liked)
  }

  return (
    <div className="lesson-review">
      <h3>{lang.REVIEW.LESSON.TITLE}</h3>
      <p>{lang.REVIEW.LESSON.TEXT}</p>
      <div className="lesson-review-wrap">
        <button
          className={`like ${(like === null ? false : like) ? 'changed' : ''}`}
          onClick={() => changeLike(true)}>
          <Icon name="like" color={like === null ? false : like}/></button>
        <button
          className={`dislike ${(like === null ? false : !like) ? 'changed' : ''}`}
          onClick={() => changeLike(false)}><Icon name="dislike"
                                                  color={like === null ? false : !like}/>
        </button>
      </div>
    </div>
  )
}
export default LessonReview;
