import HttpClient from "../services/http.client";

export const getCourses = async () => {
  const authToken = localStorage.getItem("ACCESS_TOKEN")
    ? `JWT ${localStorage.getItem("ACCESS_TOKEN")}` : ""

  return HttpClient.get('courses/', {
    headers: {"Authorization": authToken}
  })
}
