import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import HeaderDropdown from './HeaderDropdown/HeaderDropdown';
import HeaderMenu from './HeaderMenu';
import { Alert, Icon } from '../../components';
import './Header.scss';
import MessageModal from './MessageModal';
import { ReactComponent as BellIcon } from "../../assets/icons/bell.svg";
import clsx from "clsx";
import Notifications from "./Notifications";

const Header = ({
  lang,
  setLang,
  langName,
  taskCategories,
  user,
  logout,
  history
}) => {
  const [menu, setMenu] = useState(false)
  const elementRef = useRef(null);
  const notificationsRef = useRef(null);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isHeaderShow, setHeaderShow] = useState(false);
  const [isMessageModal, setMessageModal] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState(false);
  const [isNotificationsShow, setNotificationsShow] = useState(false);
  const [unreadNotificationsExists, setUnreadNotificationsExists] = useState(false);

  const isMobile = window.innerWidth <= 768;

  const defineActiveRoute = (pathname) => {
    if (history.location.pathname === pathname) {
      return ' link__active';
    }
    return '';
  }

  const saveCurrentLang = (changLang) => {
    localStorage.setItem('currentLang', changLang)
    setLang(changLang)
  }

  useEffect(() => {
    const pathnameArray = history.location.pathname.split('/');

    if (isMobile && (pathnameArray.includes('lessons') && !((pathnameArray[pathnameArray.length - 1] === '') && pathnameArray.includes('courses')))) {
      setHeaderShow(true)
    }

    document.addEventListener('click', handleClickOutside, false)
    document.addEventListener('click', handleClickOutsideNotifications, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
      document.removeEventListener('click', handleClickOutsideNotifications, false)
    }
  }, [])

  const handleClickOutside = (event) => {
    if ((!elementRef.current || !elementRef.current.contains(event.target))) {
      setIsDropdown(false)
    }
  }

  const handleClickOutsideNotifications = (event) => {
    if ((!notificationsRef.current || !notificationsRef.current.contains(event.target))) {
      setNotificationsShow(false)
    }
  }

  const changeMenu = (isOpen) => {
    setMenu(isOpen);
    isOpen ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open');
  }

  const changeMessageModal = (isOpen) => {
    setMessageModal(isOpen);
    isOpen ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open');
  }

  return (
    <div
      className="header"
      style={isHeaderShow ? { display: 'none' } : { display: 'block' }}
    >
      <div className="container desktop-block">
        <div className="header-wrap">
          <div className="header-nav">
            {/* <div className="d-flex a-i-c">
              <Link to="/" style={{color: 'inherit'}}>
                <img src="/assets/img/logo-jas.svg" alt="logo"
                  className="logo-jas" />
              </Link>
            </div> */}
            {
              user && (
                <Link className={'link' + defineActiveRoute('/courses')}
                  to={`/`}>
                  <Icon name="catalog" />
                  {lang.HEADER.LINKS.CATALOG}
                </Link>
              )
            }
          </div>
          <div className="header-inner">
            {!user && <Link
              to="/auth"
              style={{ marginRight: '16px' }}
            >
              {lang.AUTH.LOGIN_ACCOUNT}
            </Link>}

            <select name="lang" id="lang" className="lang"
              value={langName}
              onChange={
                // eslint-disable-next-line no-restricted-globals
                () => saveCurrentLang(event.target.value)
              }>
              <option value="ru">RU</option>
              <option value="en">EN</option>
              <option value="kz">KZ</option>
            </select>
            {
              user &&
              <Fragment>
                <button className="header__message desktop-flex"
                  onClick={() => changeMessageModal(true)}>
                  {lang.HEADER.MESSAGE}
                  <Icon name="message" />
                </button>
                <button
                  ref={notificationsRef}
                  className={clsx("header__bell", { active: isNotificationsShow })}
                  onClick={() => setNotificationsShow(!isNotificationsShow)}
                >
                  <BellIcon />
                  {unreadNotificationsExists && <div className="header__bell__badge" />}

                  <Notifications
                    show={isNotificationsShow}
                    lang={lang}
                    setUnreadNotificationsExists={setUnreadNotificationsExists}
                  />
                </button>
                <button ref={elementRef}
                  className={`header-user ${isDropdown ? 'active' : ''}`}
                  onClick={() => setIsDropdown(!isDropdown)}>
                  <h3 className="header-user__name">{user.first_name}</h3>
                  <img
                    src={user.avatar_url ? user.avatar_url : '/assets/img/avatar-placeholder.svg'}
                    alt="avatar"
                    className="avatar" />
                  {isDropdown && <HeaderDropdown lang={lang} logout={logout}
                    setIsDropdown={setIsDropdown} />}
                </button>
              </Fragment>
            }
          </div>
        </div>
      </div>

      <div className="header-mobile mobile-flex">
        {user && (
          <button className="header-mobile-menu"
            onClick={() => changeMenu(true)}>
            <img src="/assets/icons/menu.svg" alt="menu" />
          </button>
        )}

        {/* <div className="logo-wrap">
          <Link to="/">
            <img src="/assets/img/logo-jas.svg" alt="logo"
              className="logo-jas" />
          </Link>
        </div> */}

        {!user && <Link
          to="/auth"
          style={{ marginRight: '16px', display: 'block' }}
        >
          {lang.AUTH.LOGIN_ACCOUNT}
        </Link>}

        <select name="lang" id="lang" className="lang"
          value={langName}
          onChange={
            // eslint-disable-next-line no-restricted-globals
            () => saveCurrentLang(event.target.value)
          }>
          <option value="ru">RU</option>
          <option value="en">EN</option>
          <option value="kz">KZ</option>
        </select>

        {history.location.pathname === '/profile' ?
          <Link to="/profile/edit"><img className="header__icon"
            src="/assets/icons/edit.svg"
            alt="edit" /></Link> : ''}
        {history.location.pathname === '/profile/edit' ?
          <Link to="/profile"><img className="header__icon"
            src="/assets/icons/edit.svg"
            alt="edit" /></Link> : ''}
      </div>

      {menu ? <HeaderMenu lang={lang} setLang={setLang} logout={logout}
        changeMessageModal={changeMessageModal}
        changeMenu={changeMenu} user={user} /> : ''}

      {isMessageModal ?
        <MessageModal lang={lang} taskCategories={taskCategories}
          setSuccessMessage={setSuccessMessage}
          changeMessageModal={changeMessageModal}
          user={user} /> : ''}
      {isSuccessMessage ? <Alert text={lang.SUCCESS_MESSAGE} /> : ''}
    </div>
  );
}

export default withRouter(Header);
