import icons from './icons.json';
import '../components.scss';

const Icon = ({name, color}) => {
  return (
    <div className={`icon ${color === true ? 'svg--color' : ''}`}
         dangerouslySetInnerHTML={{__html: icons[name]}}></div>
  )
}
export default Icon;
