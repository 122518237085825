import './CourseProgressCircle.scss';

const CourseProgressCircle = ({courseItem}) => {

  const check = () => {
    if (courseItem.is_available) {
      if (courseItem.is_passed) {
        return <div className="passed"><span>✅</span></div>;
      }
      return (
        <svg viewBox="0 0 36 36" className="circular-chart green">
          <path className="circle-bg"
                d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path className="circle"
                strokeDasharray={courseItem.progress + ", 100"}
                d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="20.35" className="percentage">{courseItem.progress}%
          </text>
        </svg>
      )
    }
    return <div className="no-available"><span>🔒</span></div>;
  }
  return (
    <div className="single-chart">{check()}</div>
  )
}

export default CourseProgressCircle;
