import {ErrorMessage, Field, Form, Formik} from 'formik';
import {Fragment, useState} from 'react';
import {useRouteMatch, withRouter} from 'react-router';
import {resetPassword} from '../Auth.service';
import {ResetValidation} from '../Validation';
import {Alert} from '../../components';
import './ResetPassword.scss';

const ResetPassword = ({lang, history}) => {
  let match = useRouteMatch();

  const [isPasswordType, setPasswordType] = useState(true)
  const [isRePasswordType, setRePasswordType] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isMessage, setMessage] = useState(false);

  const onSubmit = (values) => {
    if (values.new_password === values.re_new_password) {
      const data = {
        uid: match.params.uid,
        token: match.params.token,
        new_password: values.new_password,
        re_new_password: values.re_new_password
      }
      resetPassword(data).then(res => {
        setMessage(true)
        setTimeout(() => {
          history.push('/auth')
        }, 3000)
      }).catch(error => {
        setErrorMessage(error.response.data.new_password)
        setTimeout(() => {
          setErrorMessage(null)
        }, 3000)
      })
    }
  }
  return (
    <Fragment>
      <h2
        style={{textAlign: 'center'}}>{lang.AUTH.ACCESS_RECOVERY.PASSWORD}</h2>
      <Formik
        initialValues={{new_password: '', re_new_password: ''}}
        validate={values => ResetValidation(values, lang)}
        onSubmit={onSubmit}>
        {({values}) => (
          <Form>
            <p className="label">{lang.AUTH.NEW_PASSWORD}</p>
            <Field type={isPasswordType ? 'password' : 'text'}
                   name="new_password"
                   placeholder={lang.AUTH.PASSWORD_PLACEHOLDER}/>
            <a onClick={() => setPasswordType(!isPasswordType)}>
              {isPasswordType ?
                <img className="input__icon" src="/assets/icons/eye.svg"
                     alt="eye"/> :
                <img className="input__icon" src="/assets/icons/eye-off.svg"
                     alt="eye-off"/>}
            </a>
            <ErrorMessage className="error-message" name="new_password"
                          component="div"/>

            <p className="label">{lang.AUTH.RE_PASSWORD}</p>
            <Field type={isRePasswordType ? 'password' : 'text'}
                   name="re_new_password"
                   placeholder={lang.AUTH.PASSWORD_PLACEHOLDER}/>
            <a onClick={() => setRePasswordType(!isRePasswordType)}>
              {isRePasswordType ?
                <img className="input__icon" src="/assets/icons/eye.svg"
                     alt="eye"/> :
                <img className="input__icon" src="/assets/icons/eye-off.svg"
                     alt="eye-off"/>}
            </a>
            <ErrorMessage className="error-message" name="re_new_password"
                          component="div"/>

            <div className="error-message">{errorMessage}</div>
            <button className="auth__btn" type="submit"
                    disabled={!values.new_password || !values.re_new_password}>{lang.AUTH.CHANGE_PASSWORD}</button>
          </Form>
        )}
      </Formik>
      {isMessage ? <Alert text={lang.AUTH.SUCCESS_RE_PASSWORD}/> : ''}
    </Fragment>
  )
}
export default withRouter(ResetPassword);
