import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";

import Courses from "./Courses";
import Auth from "./Auth/Auth";
import {getTaskCategories, getUser} from "./Auth/Auth.service";
import TranslateService from "./translate";
import {useEffect, useState} from "react";
import Header from "./Layout/Header";
import Modules from "./Modules";
import Profile from "./Profile/Profile";
import ScrollToTop from "./ScrollToTop";
import {Redirect} from "react-router";
import Footer from "./Layout/Footer";
import {ToastContainer} from 'react-toastify';


const App = () => {
  const currentLang = localStorage.getItem("currentLang");
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");

  const [lang, setLang] = useState(TranslateService("ru"));
  const [langName, setLangName] = useState("ru");
  const [user, setUser] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(!!ACCESS_TOKEN);
  const [taskCategories, setTaskCategories] = useState(null);

  const logout = () => {
    setUser(null);
    setLoggedIn(false);
    localStorage.clear();
  };

  useEffect(() => {
    if (currentLang) {
      setLang(TranslateService(currentLang));
      setLangName(currentLang);
    } else {
      setLang(TranslateService("ru"));
      setLangName("ru");
    }

    if (isLoggedIn) {
      getUser().then((user) => {
        setUser(user);
      });

      getTaskCategories().then((res) => {
        setTaskCategories(res);
      });
    }
  }, [isLoggedIn, currentLang]);

  return (
    <div id="content">
      <ToastContainer/>

      <Router>
        <ScrollToTop/>

        <Header
          taskCategories={taskCategories}
          lang={lang}
          user={user}
          logout={logout}
          langName={langName}
          setLang={(selectedLang) => setLang(TranslateService(selectedLang))}
        />

        <main>
          <Switch>
            <Route exact path="/" render={() => <Courses lang={lang}/>}/>

            <Route
              path="/auth"
              render={() => <Auth lang={lang} setLoggedIn={setLoggedIn}/>}
            />

            <Route
              path="/profile"
              render={() => <Profile lang={lang} user={user}
                                     setUser={setUser}/>}
            />

            <Route
              path="/courses/:id"
              render={() => (
                ACCESS_TOKEN
                  ? <Modules lang={lang} user={user}/>
                  : <Redirect to="/auth"/>)}
            />

            <Route path="*">
              <Redirect to="/"/>
            </Route>
          </Switch>
        </main>


        {/* <Footer lang={lang}/> */}
      </Router>
    </div>
  );
};

export default App;
