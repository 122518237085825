import HttpClient from '../services/http.client';

export const getUser = async () => {
  return HttpClient.get('users/me/')
}
export const login = async (data) => {
  return HttpClient.post('auth/login/', data)
}
export const createTask = async (data) => {
  return HttpClient.post('users/tasks/', data)
}
export const getTaskCategories = async () => {
  return HttpClient.get('users/tasks/categories/')
}
export const register = async (data) => {
  return HttpClient.post('users/', data, {
    headers: {
      Authorization: ''
    }
  })
}
export const activation = async (data) => {
  return HttpClient.post('users/activation/', data, {
    headers: {
      Authorization: ''
    }
  })
}
export const reActivation = async (email) => {
  return HttpClient.post('users/activation/resend/', email, {
    headers: {
      Authorization: ''
    }
  })
}

export const forgorPassword = async (email) => {
  return HttpClient.post('users/password/reset/', email, {
    headers: {
      Authorization: ''
    }
  })
}

export const resetPassword = async (data) => {
  return HttpClient.post('users/password/confirm/', data, {
    headers: {
      Authorization: ''
    }
  })
}
