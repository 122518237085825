import HttpClient from '../services/http.client';

export const getMyCourses = async () => {
  return HttpClient.get('/users/me/courses/')
}
export const editProfile = (data) => {
  return HttpClient.put('/users/me/', data)
}
export const addFile = (data) => {
  return HttpClient.post('/files/', data)
}
export const getMyCertificates = async () => {
  return HttpClient.get('/user/me/certificates/')
}
export const createLegalEntity = (data) => {
  return HttpClient.post('/users/entity/', data)
}
