import {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Alert} from '../../../components';
import './ModuleCard.scss';
import ModuleLessonsItem from './ModuleLessonsItem/ModuleLessonsItem';

const ModuleCard = ({moduleItem, lastItem, lang, courseId, history}) => {
  const [more, setMore] = useState(false)
  const [isReview, setReview] = useState(false)
  const onNavigateLesson = (lesson) => {
    if (lesson.is_available && !lesson.is_review) {
      history.push(`/courses/${courseId}/modules/${moduleItem.id}/lessons/${lesson.id}`)
    } else if (lesson.is_review) {
      setReview(true)
      setTimeout(() => {
        setReview(false)
      }, 3000);
    }
  }
  return (
    <div className="module-card">
      <div className="module-card-header">
        <h4 className="module-card__title">{moduleItem.title}</h4>
        <div className="module-card-header-content">
          <div className="module-card-header-content-wrap">
            <img src="/assets/icons/clock.svg" alt="clock"/>
            {moduleItem.duration_in_hours}
            {lang.COURSES.SHORT_HOURS}
          </div>
          {moduleItem.is_available ? (<p
              className={moduleItem.is_passed ? 'success' : ''}>{moduleItem.is_passed ? lang.COURSES.PASSED : `${moduleItem.progress}%`}</p>) :
            <img src="/assets/icons/lock.svg" alt="lock"/>}
        </div>
      </div>
      <p
        className="module-card__description desktop-block">{moduleItem.description}</p>
      {more ?
        <p className="module-card__description">{moduleItem.description}</p> :
        <p className="module-card__description mobile-block">
          {moduleItem.description.substring(0, 74)} ...
          <a onClick={() => setMore(true)}>{lang.COURSES.MORE}</a>
        </p>
      }
      {moduleItem.lessons.map((lesson) => {
        return (
          <a key={lesson.id}
             onClick={() => onNavigateLesson(lesson)}><ModuleLessonsItem
            lesson={lesson}/></a>
        )
      })
      }
      {isReview ? <Alert text={lang.REVIEW.MESSAGE}/> : ''}
    </div>
  );
}

export default withRouter(ModuleCard);
