import {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Alert} from '../../components';
import LessonsItem from '../LessonsItem';
import './LessonsNavigation.scss';

const LessonsNavigation = ({
                             lang,
                             courseId,
                             modules,
                             currentLesson,
                             show,
                             setShow,
                             isMobileDeviceWidth,
                             history
                           }) => {
  const [isReview, setReview] = useState(false)

  const onNavigationHandler = (module, lesson) => {
    if (lesson.is_available && !lesson.is_review) {
      history.push(`/courses/${courseId}/modules/${module.id}/lessons/${lesson.id}`)
      if (isMobileDeviceWidth) {
        setShow(!show)
      }
    } else if (lesson.is_review) {
      setReview(true)
      setTimeout(() => {
        setReview(false)
      }, 3000);
    }
  }

  return (
    <div className={`navigation ${show ? 'navigation-desktop-translate' : ''}`}>
      <button className="navigation__btn" onClick={() => {
        setShow(!show)
      }}>
        {show ? <img src="/assets/icons/nav-menu-white.svg" alt="menu"/> :
          <img src="/assets/icons/arrow-white.svg" alt="arrow"/>}
      </button>
      <div className="navigation-wrap">
        <h2 className="desktop-block">{lang.COURSES.CONTENT}</h2>
        <div className="mobile-flex navigation-header">
          <h3>{lang.COURSES.CONTENT}</h3>
          <button onClick={() => setShow(!show)}/>
        </div>
        {modules.map((module) => {
          return (
            <div key={module.id} className="navigation__item">
              <h3>{module.title}</h3>
              {module.lessons.map((lesson) => {
                return (
                  <a key={lesson.id}
                     onClick={() => onNavigationHandler(module, lesson)}>
                    <LessonsItem lesson={lesson}/>
                  </a>
                )
              })}
            </div>
          )
        })}
      </div>
      {isReview ? <Alert text={lang.REVIEW.MESSAGE}/> : ''}
    </div>
  )
}
export default withRouter(LessonsNavigation);
