import {useEffect, useState} from "react";
import {getMyCertificates, getMyCourses} from "./Profile.servise";
import {Route, Switch, useRouteMatch, withRouter} from "react-router";
import ProfileEdit from "./ProfileEdit";
import "./Profile.scss";

import ProfileUserData from "./ProfileUserData/ProfileUserData";

const Profile = ({lang, user, setUser, history}) => {
  let match = useRouteMatch();

  const [passedCourses, setPassedCourses] = useState([]);
  const [activeCourses, setActiveCourses] = useState([]);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    getMyCourses().then((courses) => {
      const filteredPassedCourses = courses.filter(
        (course) => course.is_passed
      );
      const filteredActiveCourses = courses.filter(
        (course) => !course.is_passed
      );
      setActiveCourses(filteredActiveCourses);
      setPassedCourses(filteredPassedCourses);
    });
    getMyCertificates().then((certificates) => {
      setCertificates(certificates);
    });
  }, [setPassedCourses, setActiveCourses, setCertificates]);

  return user ? (
    <div className="profile">
      <div className="container">
        <Switch>
          <Route exact path={`${match.path}`}>
            <ProfileUserData
              lang={lang}
              user={user}
              certificates={certificates}
              passedCourses={passedCourses}
              activeCourses={activeCourses}
            />
          </Route>
          <Route exact path={`${match.path}/edit`}>
            <ProfileEdit lang={lang} user={user} setUser={setUser}/>
          </Route>
        </Switch>
      </div>
    </div>
  ) : (
    ""
  );
};

export default withRouter(Profile);
