const WordEndingService = (number, titles) => {
  var cases = [2, 0, 1, 1, 1, 2];
  return titles[
    (number % 100 > 4 && number % 100 < 20)
      ?
      2
      :
      cases[(number % 10 < 5) ? number % 10 : 5]
    ];
}
export default WordEndingService;

//WordEndingService(days, ['день', 'дня', 'дней']);
//WordEndingService(hours, ['час', 'часа', 'часов']);
//WordEndingService(minutes, ['минута', 'минуты', 'минут']);
//WordEndingService(seconds, ['секунда', 'секунды', 'секунд']);