import axios from 'axios';
import {API_BASE_URL} from "../shared/constants";

const ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN');
const AUTH_HEADER = `JWT ${ACCESS_TOKEN}`;

const httpClientParams = {
  baseURL: API_BASE_URL,
  headers: {'Authorization': AUTH_HEADER}
}

const HttpClient = axios.create(httpClientParams);

HttpClient.interceptors.response.use(
  (response) => {
    if (response.config.url === 'auth/login/') {
      HttpClient.defaults.headers = {'Authorization': `JWT ${response.data.access}`}
    }
    return response.data;
  },
  (error) => {
    if (error.response) {
      console.log(error.response)
      if (error.response.status === 401) {
        localStorage.removeItem('ACCESS_TOKEN');

        if (error.response.config.url !== 'auth/login/' && error.response.config.url !== 'users/') {
          window.location.href = '/auth';
        }
      }
    }
    return Promise.reject(error);
  });

export default HttpClient;
