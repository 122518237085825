import {useEffect, useState} from 'react';
import {useRouteMatch, withRouter} from 'react-router';
import './LessonDirection.scss';

const LessonDirection = ({
                           lang,
                           selectedModule,
                           modules,
                           selectedLesson,
                           history
                         }) => {
  let match = useRouteMatch()
  const url = match.url.substr(0, match.url.lastIndexOf("/") + 1)
  const pathnameArray = history.location.pathname.split('/');
  const courseId = pathnameArray[2];
  const [allLessons, setAllLessons] = useState([])

  useEffect(() => {
    const lessons = []
    modules.map(module => {
      module.lessons.map(lesson => {
        lessons.push(lesson)
      })
    })
    setAllLessons(lessons)
  }, [])

  const selectedLessonPosition = (arg) => {
    return selectedModule.lessons.findIndex((lesson) => lesson.id === selectedLesson.id) + arg
  }
  const navigate = (inc) => {
    if (selectedLessonPosition(1) === selectedModule.lessons.length && inc === 1) {
      const idx = modules.findIndex((item) => item.id === selectedModule.id)
      const nextModule = modules.find((item, index) => index === (idx + 1))

      if (nextModule) {
        history.push(`/courses/${courseId}/modules/${nextModule.id}/lessons/${nextModule.lessons[0].id}`)
      }
    } else {
      history.push(url + selectedModule.lessons[selectedLessonPosition(inc)].id)
    }
  }

  const checkDisabled = (arg) => {
    if (allLessons.length > 0) {
      if (arg === 'next') {
        const idx = allLessons.findIndex(item => item.id === selectedLesson.id)

        if (allLessons[idx + 1]) {

          console.log(selectedLesson)

          if (!allLessons[idx + 1].is_available) {
            return true
          }
        }

        return allLessons[allLessons.length - 1].id === selectedLesson.id && allLessons[idx].is_solved
      }
      return allLessons[0].id === selectedLesson.id
    }

    return false
  }

  return (
    <div className="lesson-direction">
      <div className="lesson-direction-wrap">

        <button
          onClick={() => navigate(-1)}
          disabled={checkDisabled('prev')}
          className="btn--action desktop-block"
        >
          {lang.PREV}
        </button>

        <button
          onClick={() => navigate(-1)}
          disabled={checkDisabled('prev')}
          className="lesson-direction--arrow left mobile-block"
        />

        <div className="lesson-direction-nav">
          <span>{selectedLessonPosition(1)}</span> / {selectedModule.lessons.length}
        </div>

        <button
          onClick={() => navigate(1)}
          disabled={checkDisabled('next')}
          className="lesson-direction--arrow right mobile-block"
        />

        <button
          onClick={() => navigate(1)}
          disabled={checkDisabled('next')}
          className="btn--primary desktop-block"
        >
          {lang.NEXT}
        </button>
      </div>
    </div>
  )
}

export default withRouter(LessonDirection);
