import {useState} from "react";
import Login from "./Login";
import Register from "./Register";
import AccessRecovery from "./AccessRecovery";
import AuthMessage from "./AuthMessage";
import "./Auth.scss";
import {Route, Switch, useRouteMatch, withRouter} from "react-router-dom";
import Activation from "./Activation";
import ResetPassword from "./ResetPassword";

const Auth = ({lang, setLoggedIn}) => {
  let match = useRouteMatch();

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const success = (pos) => {
    const crd = pos.coords;
    console.log("Ваше текущее местоположение:");
    console.log(`Широта: ${crd.latitude}`);
    console.log(`Долгота: ${crd.longitude}`);
    console.log(`Плюс-минус ${crd.accuracy} метров.`);
  };

  const error = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  navigator.geolocation.getCurrentPosition(success, error, options);

  const [isActive, setIsActive] = useState(true)
  const [infoModal, setInfoModal] = useState(true)
  const [message, setMessage] = useState(null)

  const defineActiveTab = (type) => {
    if (type) {
      return " link__active";
    }
    return "";
  };

  return (
    <section className={`auth ${message ? 'auth-message' : null}`}>
      {/* <div onClick={() => setInfoModal(false)} className="auth__modal-close-fon" style={infoModal ? {display: 'block'} : {display: 'none'}}></div> */}
      <div className="auth-wrap">
        {/* {infoModal ?
          <div className="auth__modal-info">
            <div className="auth__modal-bg">
              <div className="auth__img-wrap">
                <img onClick={() => setInfoModal(false)} src="/assets/icons/close.svg" alt=""/>
              </div>
              <p className="label">{lang.AUTH.MODAL.INFO}</p>
            </div>
          </div> :
        null} */}
        {message ? <AuthMessage lang={lang} message={message} setMessage={setMessage}/> :
          <Switch>
            <Route exact path={`${match.path}`}>
              <div className="auth-nav">
                <a
                  className={"link" + defineActiveTab(isActive)}
                  onClick={() => setIsActive(true)}
                >
                  {lang.AUTH.LOGIN}
                </a>
                <a
                  className={"link" + defineActiveTab(!isActive)}
                  onClick={() => setIsActive(false)}
                >
                  {lang.AUTH.REGISTER}
                </a>
              </div>
              {isActive ? <Login lang={lang} setLoggedIn={setLoggedIn}
                                 setInfoModal={setInfoModal}/> :
                <Register lang={lang} setMessage={setMessage}
                          setInfoModal={setInfoModal}/>}
            </Route>
            <Route exact path={`${match.path}/forgot-password`}>
              <AccessRecovery lang={lang} setMessage={setMessage}/>
            </Route>
            <Route exact path={`${match.path}/activate/:uid/:token`}>
              <Activation lang={lang}/>
            </Route>
            <Route exact path={`${match.path}/password_reset/:uid/:token`}>
              <ResetPassword lang={lang}/>
            </Route>
          </Switch>
        }
      </div>
    </section>
  );
};
export default withRouter(Auth);
