import React, {Component} from "react";
import Slider from "react-slick";
import './LessonPresentation.scss'

export default class LessonPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const settingsRight = {
      asNavFor: this.state.nav2,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      infinite: true,
      centerMode: true,
      centerPadding: "13vh",
      ref: slider => (this.slider1 = slider)
    }
    const settingsLeft = {
      asNavFor: this.state.nav1,
      ref: slider => (this.slider2 = slider),
      slidesToShow: this.props.presentation.length,
      swipeToSlide: true,
      focusOnSelect: true,
      arrows: false,
      vertical: true
    }
    return (
      <div className="presentation">
        <div className="presentation-slider__left">
          <Slider {...settingsLeft}>
            {this.props.presentation.map((img, index) => {
              return (
                <div key={index}>
                  <div className="presentation__img--mini"
                       style={{backgroundImage: `url(${img})`}}/>
                </div>
              )
            })}
          </Slider>
        </div>
        <div className="presentation-slider__right">
          <div className="presentation-close" onClick={() => {
            this.props.changePresentationModal(false)
          }}/>
          <Slider {...settingsRight}>
            {this.props.presentation.map((img, index) => {
              return (
                <div key={index}>
                  <div className="presentation__img"
                       style={{backgroundImage: `url(${img})`}}/>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    );
  }
}
