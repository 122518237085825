import './CourseReview.scss'
import CertificateModule from './CertificateModal'
import {useEffect, useState} from 'react'
import {stars, stars2} from './stars'
import {sendReview} from '../Modules.service'
import {withRouter} from 'react-router'

const CourseReview = ({lang, course, setShow, history}) => {
  const [rateStars, setRateStars] = useState(stars)
  const [secRateStars, setSecRateStars] = useState(stars2)
  const [certificateModal, setCertificateModal] = useState(false)
  const [reviewText, setReviewText] = useState('')
  const [secReviewText, setSecReviewText] = useState('')
  const [currentStarId, setCurrentStarId] = useState(null)
  const [secCurrentStarId, setSecCurrentStarId] = useState(null)
  const [certificateData, setCertificateData] = useState(null)
  const [secCertificateData, setSecCertificateData] = useState(null)
  const [dinamicWord, setDinamicWord] = useState('')
  const [estimationStorageWord, setEstimationStorageWord] = useState('')
  const [dinamicWord2, setDinamicWord2] = useState('')
  const [estimationStorageWord2, setEstimationStorageWord2] = useState('')

  useEffect(() => {
    if (course.is_reviewed) {
      history.push('/')
    }

    return () => {
      setRateStars(clearStarsActive(stars))
      setSecRateStars(clearStarsActive(stars2))
    }
  }, [])

  const clearStarsActive = (newStars) => {
    return newStars.map(star => {
      star.isFixed = false
      star.isColor = false
      return star
    })
  }
  // --- Замена слов оценки при наведении мыши
  const defineDinamicWord = (currentStar, setWord) => {
    if (currentStar.id <= 3) {
      setWord(lang.REVIEW.GRADE_1)
    } else if (currentStar.id > 3 && currentStar.id <= 5) {
      setWord(lang.REVIEW.GRADE_2)
    } else if (currentStar.id > 5 && currentStar.id <= 7) {
      setWord(lang.REVIEW.GRADE_3)
    } else if (currentStar.id > 7 && currentStar.id <= 9) {
      setWord(lang.REVIEW.GRADE_4)
    } else {
      setWord(lang.REVIEW.GRADE_5)
    }
  }
  // --- Замена слов оценки при наведении мыши

  const changeStarColorAndText = (currentStar, isFixed) => {
    const newStars = rateStars.map((star) => {
      if (star.id <= currentStar.id) {

        //--- Фиксация текста оценки при клике на звезду
        if (isFixed) {
          defineDinamicWord(currentStar, setDinamicWord)
          setEstimationStorageWord(dinamicWord)
        }
        //--- Фиксация текста оценки при клике на звезду

        star.isColor = true
        if (isFixed) {
          star.isFixed = true
        }
      } else {
        star.isColor = false
        if (isFixed) {
          star.isFixed = false
        }
      }
      return star
    })
    setRateStars(newStars)
    setCurrentStarId(currentStar.id)
  }

  const changeStarColorAndTextSec = (currentStar, isFixed) => {
    const newStarsSec = secRateStars.map((star2) => {
      if (star2.id <= currentStar.id) {

        //--- Фиксация текста оценки при клике на звезду для ментора
        if (isFixed) {
          defineDinamicWord(currentStar, setDinamicWord2)
          setEstimationStorageWord2(dinamicWord2)
        }
        //--- Фиксация текста оценки при клике на звезду для ментора

        star2.isColor = true
        if (isFixed) {
          star2.isFixed = true
        }
      } else {
        star2.isColor = false
        if (isFixed) {
          star2.isFixed = false
        }
      }
      return star2
    })
    setSecRateStars(newStarsSec)
    setSecCurrentStarId(currentStar.id)
  }
  const addReview = (isCertificate) => {
    const data = {
      course: course.id,
      review_text: reviewText,
      grade: currentStarId,
      mentor_review: secReviewText,
      mentor_grade: secCurrentStarId
    }
    sendReview(data).then(res => {
      if (res.certificate) {
        setCertificateData(res.certificate)
      }
    })
    setShow(true)
    setCertificateModal(isCertificate)
    history.push('/')
  }

  const moveStarColorAndText = (currentStar, isMove) => {
    if (isMove) {
      changeStarColorAndText(currentStar, false)

      //--- Изменени текста оценки при наведении на звезду
      defineDinamicWord(currentStar, setDinamicWord)
      //--- Изменени текста оценки при наведении на звезду

    } else {
      setDinamicWord(estimationStorageWord)
      const newStars = rateStars.map((star) => {
        star.isColor = false
        return star
      })
      setRateStars(newStars)
    }
  }

  const moveStarColorAndTextSec = (currentStar, isMove) => {
    if (isMove) {
      changeStarColorAndTextSec(currentStar, false)

      //--- Изменени текста оценки при наведении на звезду для ментора
      defineDinamicWord(currentStar, setDinamicWord2)
      //--- Изменени текста оценки при наведении на звезду для ментора

    } else {
      setDinamicWord2(estimationStorageWord2)
      const newStarsSec = secRateStars.map((star) => {
        star.isColor = false
        return star
      })
      setSecRateStars(newStarsSec)
    }
  }
  const definePercentProgress = (newStars) => {
    const idx = newStars.findIndex((star) => !star.isFixed && !star.isColor)
    if (idx) {
      if (newStars[idx - 1]) {
        return `${newStars[idx - 1].id * 10}%`
      }
      return '100%'
    }
    return '0'
  }

  return (
    <div className="app_wrapper">
      <div className="course-review">
        <div className="course-review__img__logo">
          {course.has_certificate ?
            <img src="/assets/img/image_student_with_certificate.png"
                 alt="NOT LOGO"/> :
            <img src="/assets/img/image_student.png" alt="NOT LOGO"/>}
        </div>
        <h2 className="course-review__title">{lang.REVIEW.END_COURSE}</h2>
        <div className="course-review__subtitle">
          <p>{course.has_certificate ? lang.REVIEW.RATE_2 : lang.REVIEW.RATE_1}</p>
        </div>
        <div className="course-review-wrap">
          <div className="course-review__stars__rating">
            {rateStars.map(star => {
              return (
                <div
                  key={star.id}
                  className={`star ${star.isColor || star.isFixed ? 'star-active' : ''}`}
                  dangerouslySetInnerHTML={{__html: star.icon}}
                  onClick={() => changeStarColorAndText(star, true)}
                  onMouseMove={() => moveStarColorAndText(star, true)}
                  onMouseOut={() => moveStarColorAndText(star, false)}
                />
              )
            })}
          </div>
          <div className="course-review__progress__bar">
            <div className="course-review__progress__bar--fill"
                 style={{width: definePercentProgress(rateStars)}}/>
          </div>
        </div>
        <p className="course-review__rating__comment">{dinamicWord}</p>
        <textarea
          className="course-review__textarea"
          name="review"
          id="review"
          placeholder={lang.REVIEW.PLACEHOLDER}
          value={reviewText}
          onChange={(e) => setReviewText(e.target.value)}>
          </textarea>
        {certificateModal && certificateData ?
          <CertificateModule lang={lang} certificate={certificateData}
                             course={course}/> : ''}
        <div className="course-review__subtitle">
          <p>{course.has_certificate ? lang.REVIEW.RATE_4 : lang.REVIEW.RATE_3}</p>
        </div>
        <div className="course-review-wrap">
          <div className="course-review__stars__rating">
            {secRateStars.map(star => {
              return (
                <div
                  key={star.id}
                  className={`star ${star.isColor || star.isFixed ? 'star-active' : ''}`}
                  dangerouslySetInnerHTML={{__html: star.icon}}
                  onClick={() => changeStarColorAndTextSec(star, true)}
                  onMouseMove={() => moveStarColorAndTextSec(star, true)}
                  onMouseOut={() => moveStarColorAndTextSec(star, false)}
                ></div>
              )
            })}
          </div>
          <div className="course-review__progress__bar">
            <div className="course-review__progress__bar--fill"
                 style={{width: definePercentProgress(secRateStars)}}></div>
          </div>
        </div>
        <p className="course-review__rating__comment">{dinamicWord2}</p>
        <textarea
          className="course-review__textarea"
          name="review"
          id="review2"
          placeholder={lang.REVIEW.PLACEHOLDER}
          value={secReviewText}
          onChange={(e) => setSecReviewText(e.target.value)}>
          </textarea>
        {course.has_certificate ?
          <button
            disabled={(secReviewText || reviewText) === '' || (!currentStarId || !secCurrentStarId)}
            onClick={() => addReview(true)}
            className="course-review__btn">{lang.REVIEW.CERTIFICATE}</button> :
          <button className="course-review__btn"
                  disabled={(secReviewText || reviewText) === '' || (!currentStarId || !secCurrentStarId)}
                  onClick={() => addReview(false)}>{lang.REVIEW.COMPLETION}</button>}
        {certificateModal && certificateData ?
          <CertificateModule lang={lang} certificate={certificateData}
                             course={course}/> : ''}
      </div>
    </div>
  )
}

export default withRouter(CourseReview)
