import {Link} from "react-router-dom";
import CourseCard from "./CourseCard";
import './CoursesList';

const CoursesList = ({coursesList, lang}) => {
  return (
    <div className="courses-list">
      {coursesList.map((courseItem) => {
        return (
          <Link key={courseItem.id} to={`/courses/${courseItem.id}`}>
            <CourseCard key={courseItem.id} courseItem={courseItem}
                        lang={lang}/>
          </Link>
        )
      })}
    </div>
  );
}

export default CoursesList;
