import {Fragment, useEffect, useState} from 'react';
import '../Auth.scss';
import {reActivation} from '../Auth.service';
import {useLocation} from "react-router";

const AuthMessage = ({lang, message, setMessage}) => {
  const location = useLocation();

  const [isSend, setSend] = useState(true)
  const send = () => {
    const email = localStorage.getItem('reg_email')
    reActivation({email: email}).then(res => {
      setSend(false)
    })
  }

  useEffect(() => {
    if (location.pathname === '/auth' && message.title === lang.AUTH.CHECK_EMAIL.TITLE) {
      setMessage(null);
    }
  }, [location])

  return (
    <div className="auth-message-wrap">
      <h2 style={{textAlign: 'center'}}>{message.title}</h2>
      <p className="auth__text">{message.description}</p>
      {isSend ?
        <Fragment>
          <p className="auth__text">{lang.AUTH.RE_SEND.QUESTION}</p>
          <a className="auth__confirm auth__link"
             onClick={send}>{lang.AUTH.RE_SEND.CONFIRM}</a>
        </Fragment> : ''}
    </div>
  )
}
export default AuthMessage;
