import "./Loader.scss"

export default function Loader () {
  return (
    <div className="lds-ring">
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  )
}
