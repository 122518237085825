import {ErrorMessage, Field, Form, Formik} from 'formik';
import {checkEmail, LoginValidation} from '../Validation';

import '../Auth.scss';
import {Link} from 'react-router-dom';
import {forgorPassword} from '../Auth.service';

const AccessRecovery = ({lang, setMessage}) => {
  const message = (email) => {
    return {
      title: lang.AUTH.CHECK_EMAIL.TITLE,
      description: lang.AUTH.CHECK_EMAIL.DESCRIPTION + ' ' + email.toLowerCase()
    }
  }

  const onSubmit = (values, {setErrors, setFieldValue}) => {
    setFieldValue('email', values.email.toLowerCase())

    forgorPassword({email: values.email.toLowerCase()}).then(res => {
      setMessage(message(values.email))
    }).catch((err) => {
      setErrors({email: err?.response?.data?.join('') || lang.ERROR_TRY_LATER})
    })
  }

  return (
    <div className="auth-login">
      <h2 style={{textAlign: 'center'}}>{lang.AUTH.ACCESS_RECOVERY.TITLE}</h2>

      <Formik
        initialValues={{email: ''}}
        validate={values => LoginValidation(values, lang)}
        onSubmit={onSubmit}
      >
        {({values, isSubmitting}) => (
          <Form>
            <p className="label">Email</p>
            <Field type="email" name="email" placeholder="login@mail.com"/>
            {checkEmail(values.email) ?
              <img className="input__icon" src="/assets/icons/check-green.svg"
                   alt="check"/> : ''}
            <ErrorMessage className="error-message" name="email"
                          component="div"/>
            <Link className="auth__link"
                  to="/auth">{lang.AUTH.ACCESS_RECOVERY.LINK}</Link>
            <button className="auth__btn" type="submit"
                    disabled={!checkEmail(values.email)}>{lang.AUTH.ACCESS_RECOVERY.BTN}</button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default AccessRecovery;
