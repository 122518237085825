import HttpClient from "../services/http.client";
import httpClient from "../services/http.client";

export const getLessons = async (courseId, moduleId, lessonId) => {
  return HttpClient.get(
    `courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/`
  );
};
export const sendTest = async (courseId, moduleId, lessonId, test) => {
  return HttpClient.patch(
    `courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/`,
    test
  );
};
export const sendLessonReview = async (
  courseId,
  moduleId,
  lessonId,
  review
) => {
  return HttpClient.post(
    `courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/feedback/`,
    review
  );
};
export const getLessonReview = async (courseId, moduleId, lessonId) => {
  return HttpClient.get(
    `courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/feedback/`
  );
};

export const uploadAttachmentAPI = async (data, onUploadProgress) => {
  return HttpClient.post("/attachment/", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress
  });
};

export const subscribeForNotification = async (courseId, moduleId, lessonId) => {
  return httpClient.get(`courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/subscribe/`)
}

export const unsubscribeFromNotification = async (courseId, moduleId, lessonId) => {
  return httpClient.get(`courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/unsubscribe/`)
}
