import {useEffect, useState} from 'react';
import {getCourses} from './Courses.service';
import CoursesList from './CoursesList';
import './Courses.scss';
import Loader from "../components/Loader/Loader";

const Courses = ({lang}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [coursesList, setCoursesList] = useState([]);

  useEffect(() => {
    getCourses()
      .then((courses) => {
        setCoursesList(courses);
      })
      .catch((error) => {
        console.log('courses error', error);
      }).finally(() => {
      setIsLoading(false);
    });
  }, [setCoursesList]);

  return (
    <section className="courses">
      <div className="container">
        <div className="courses-wrap">
          <h1>{lang.COURSES.TITLE}</h1>
          {
            (isLoading
              ? <div style={{textAlign: "center"}}>
                <Loader/>
              </div>
              : coursesList.length
                ? <CoursesList coursesList={coursesList} lang={lang}/>
                : <p className="courses-no-active-courses">{lang.COURSES.NO_ACTIVE_COURSES}</p>)
          }
        </div>
      </div>
    </section>
  );
}

export default Courses;
