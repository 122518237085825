import "./LessonsTest.scss";
import {Checkbox, Radio} from "../../components";
import {Fragment, memo, useEffect, useState} from "react";
import clsx from "clsx";

const LessonsTest = ({
                       lang,
                       lesson,
                       attachment,
                       uploadProgress,
                       uploadStatus,
                       fileInputValue,
                       changeSingleChoiceAnswer,
                       changeMultiChoiceAnswer,
                       changeOpenEndedAnswer,
                       onChangeAttachment,
                       onRemoveAttachment,
                       onSubmit,
                     }) => {
  const [oldMentorAnswer, setOldMentorAnswer] = useState("");

  const testBtn = () => {
    const percent = 100 / lesson.quiz.criterion.length;

    const correctCriterion = lesson.quiz.criterion.filter(
      (criteria) => criteria.is_correct
    ).length;

    if (lesson.quiz.is_solved) {
      if (
        lesson.quiz.acceptance <= percent * correctCriterion &&
        lesson.quiz.type !== "mentor"
      ) {
        return {
          text: lang.QUESTION.NEXT_LESSON,
          type: "next",
        };
      } else if (lesson.quiz.type === "mentor") {
        if (
          lesson.quiz.criterion[0].is_checked &&
          lesson.quiz.criterion[0].is_correct
        ) {
          return {
            text: lang.QUESTION.NEXT_LESSON,
            type: "next",
          };
        } else if (lesson.quiz.criterion[0].is_checked) {
          return {
            text: lang.QUESTION.RE_START,
            type: "re_start",
          };
        } else {
          return {
            text: "",
            type: "pending",
          };
        }
      } else {
        return {
          text: lang.QUESTION.RE_START,
          type: "re_start",
        };
      }
    }

    return {
      text: lang.QUESTION.ANSWER,
      type: "answer",
    };
  };

  useEffect(() => {
    if (lesson.quiz.type === "mentor" && !oldMentorAnswer) {
      if (lesson.quiz.criterion.length) {
        setOldMentorAnswer(lesson.quiz.criterion[0].variants[0].answer);
      }
    }
  }, [oldMentorAnswer]);

  return (
    <div className="test lesson-content-wrap">
      <div className="test-wrap">
        <h3 className="test__title">{lang.QUESTION.TEST}</h3>

        {lesson.quiz?.type !== "mentor" && (
          <span>
            {lang.QUESTION.DEMAND}: {lesson.quiz?.acceptance}%{" "}
            {lang.QUESTION.SUCCESS_ANSWERS}
          </span>
        )}
      </div>

      <p className={`test__description`}>{lesson.quiz?.description}</p>

      {lesson.quiz?.criterion.map((criteria) => {
        return (
          <Fragment key={criteria.id}>
            {lesson.quiz?.type !== "mentor" ? (
              <h3 className="test__question">{criteria.question}</h3>
            ) : (
              <p className="test__description">{criteria.question}</p>
            )}

            {criteria.variants ? (
              <Fragment>
                {criteria.type === "single_choice" ? (
                  <div>
                    {criteria.variants.map((variant, index) => {
                      return (
                        <div key={index} className="test-inner">
                          <Radio
                            answer={variant}
                            change={() =>
                              changeSingleChoiceAnswer(
                                variant,
                                criteria.variants
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : criteria.type === "multi_choice" ? (
                  <div>
                    {criteria.variants.map((variant, index) => {
                      return (
                        <div key={index} className="test-inner">
                          <Checkbox
                            answer={variant}
                            change={() => changeMultiChoiceAnswer(variant)}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : criteria.type === "open_ended" ? (
                  <Fragment>
                    {criteria.variants.map((variant, index) => {
                      return (
                        <input
                          key={index}
                          onChange={(e) =>
                            changeOpenEndedAnswer(e.target.value, variant)
                          }
                          value={variant.answer}
                          className="open_ended"
                          type="text"
                          placeholder={lang.QUESTION.ANSWER_PLACEHOLDER}
                        />
                      );
                    })}
                  </Fragment>
                ) : (
                  <Fragment>
                    {criteria.variants.map((variant, index) => {
                      return (
                        <Fragment key={index}>
                          <label className="test__label">
                            <span>Ваш ответ:</span>
                            <br/>
                            {oldMentorAnswer && (
                              <p className="test__description">
                                {variant?.answer || oldMentorAnswer}
                              </p>
                            )}
                            {criteria.attachment?.file && (
                              <a
                                href={criteria.attachment?.file}
                                target="_blank"
                                rel="noreferrer noopener"
                                style={{
                                  display: "block",
                                  marginBottom: "8px",
                                }}
                              >
                                {lang.QUESTION.LINK_TO_FILE}
                              </a>
                            )}

                            {criteria.is_checked !== null &&
                              criteria.is_correct !== null &&
                              testBtn().type === "answer" && (
                                <Fragment>
                                  <textarea
                                    key={index}
                                    onChange={(e) =>
                                      changeOpenEndedAnswer(
                                        e.target.value,
                                        variant
                                      )
                                    }
                                    value={variant.answer}
                                    className="open_ended mentor"
                                    placeholder={
                                      lang.QUESTION.ANSWER_PLACEHOLDER
                                    }
                                  />

                                  <div className="attachment">
                                    <div className="attachment__control">
                                      <label
                                        htmlFor="attachment"
                                        className={clsx("label", {
                                          label_disabled:
                                            uploadStatus ===
                                            lang.QUESTION.UPLOADING,
                                        })}
                                      >
                                        <p>
                                          {attachment?.name ||
                                            lang.QUESTION.ATTACH_FILE}
                                        </p>
                                      </label>

                                      {attachment &&
                                        uploadStatus ===
                                        lang.QUESTION.UPLOADED && (
                                          <button onClick={onRemoveAttachment}>
                                            &times;
                                          </button>
                                        )}
                                    </div>

                                    <input
                                      id="attachment"
                                      type="file"
                                      value={fileInputValue}
                                      onChange={onChangeAttachment}
                                      disabled={
                                        uploadStatus === lang.QUESTION.UPLOADING
                                      }
                                    />

                                    {uploadProgress > 0 && (
                                      <progress
                                        value={uploadProgress}
                                        max="100"
                                      />
                                    )}

                                    {uploadStatus}
                                  </div>
                                </Fragment>
                              )}
                          </label>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              ""
            )}

            {lesson.quiz?.is_solved && lesson.quiz?.type !== "mentor" ? (
              <div
                className={`answer-${
                  criteria.is_correct ? "confirmed" : "not-confirmed"
                }`}
                // className="answer-answered"
              >
                {criteria.is_correct
                  ? lang.QUESTION.CONFIRMED
                  : lang.QUESTION.WRONG}
                {/*{lang.QUESTION.RIGHT}*/}
              </div>
            ) : lesson.quiz?.is_solved ? (
              <Fragment>
                <div
                  className={`answer-${
                    criteria.is_correct
                      ? "confirmed"
                      : criteria.is_correct !== null &&
                      criteria.is_checked &&
                      lesson.quiz.type === "mentor"
                        ? "not-confirmed"
                        : "pending"
                  }`}
                >
                  {criteria.is_correct
                    ? lesson.quiz.type === "mentor"
                      ? lang.QUESTION.CONFIRMED
                      : lang.QUESTION.RIGHT
                    : !criteria.is_correct && criteria.is_checked
                      ? lang.QUESTION.WRONG
                      : lang.QUESTION.PENDING}
                </div>
              </Fragment>
            ) : (
              ""
            )}

            {criteria.mentor_review && (
              <label className="test__label">
                <span>Ответ ментора:</span>
                <p className="test__description">{criteria.mentor_review}</p>
              </label>
            )}
          </Fragment>
        );
      })}

      {testBtn().text
        && (
          <button
            className="test__btn btn--action"
            onClick={() => {
              if (testBtn().type === "answer" && lesson.quiz?.type === "mentor") {
                setOldMentorAnswer("");
              }
              onSubmit(testBtn().type);
            }}
          >
            {testBtn().text}
          </button>
        )}
    </div>
  );
};
export default memo(LessonsTest);
