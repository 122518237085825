const LessonsItem = ({lesson}) => {
  return (
    <div key={lesson.id}
         className={'lesson__item ' + (lesson.is_available ? (lesson.is_passed ? '' : ' lesson__active') : '')}>
      <div className="lesson__item--is-passed">
        {lesson.title}
        {
          lesson.is_available ? (lesson.is_passed ?
              <img src="/assets/icons/check-green.svg" alt="check"/> :
              <img src="/assets/icons/play.svg" alt="play"/>) :
            <img src="/assets/icons/lock.svg" alt="lock"/>
        }
      </div>
    </div>
  )
}
export default LessonsItem;
