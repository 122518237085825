import {Fragment, useEffect, useState} from 'react';
import {Checkbox, Icon, Radio} from '../../components';
import './Frame.scss';

const Frame = ({lang, frame_steps, user}) => {
  const [frameSteps, setFrameSteps] = useState(frame_steps)
  const [currentFrameStep, setCurrentFrameStep] = useState(null)
  const [answers, setAnswers] = useState([''])
  const [success, setSuccess] = useState(null)
  useEffect(() => {
    if (currentFrameStep) {
      const storeCurrentFrameStep = JSON.parse(localStorage.getItem(`currentFrameStep-${currentFrameStep.id}`))
      if (storeCurrentFrameStep) {
        if (user.id === storeCurrentFrameStep.user && currentFrameStep.id === storeCurrentFrameStep.id) {
          currentFrameStep.criterion.map((criteria) => {
            criteria.variants.map((item) => {
              if (item.key === storeCurrentFrameStep.answers[0].key) {
                item.choice = true
              }
            })
          })
          setAnswers(storeCurrentFrameStep.answers)
          setSuccess(storeCurrentFrameStep.message)
        }
      }
    }
  }, [currentFrameStep])

  useEffect(() => {
    defineFrameStepForStore();
  }, [])
  const defineFrameStepForStore = () => {
    const foundFrameStepsIndexes = [];
    frameSteps.map((frameStep, index) => {
      const storeFrameStep = JSON.parse(localStorage.getItem(`currentFrameStep-${frameStep.id}`))
      if (storeFrameStep) {
        foundFrameStepsIndexes.push(index)
      }
    })
    const maxIndex = Math.max.apply(null, foundFrameStepsIndexes)
    const foundFrameStep = frameSteps.find((frameStep, index) => index === maxIndex)
    if (foundFrameStep) {
      setCurrentFrameStep(foundFrameStep)
      changeColor(foundFrameStep)
    } else {
      setCurrentFrameStep(frameSteps[0])
      changeColor(frameSteps[0])
    }
    const newFrameSteps = frameSteps.slice();
    defineAvailable(newFrameSteps, maxIndex)
    checkedForAvailable(maxIndex, newFrameSteps)
    setFrameSteps(newFrameSteps)
  }
  const defineAvailable = (newFrameSteps, maxIndex) => {
    let count = maxIndex;
    newFrameSteps.map((frameStep, index) => {
      if (index === maxIndex + 1) {
        if (frameStep.frame_type === 'test') {
          count = count + 1
        } else {
          count = count + 2
        }
        frameStep.isAvailable = true
      } else if (index === count) {
        if (frameStep.frame_type === 'test') {
          frameStep.isAvailable = true
        } else {
          frameStep.isAvailable = false
        }
      }
    })
  }
  const checkedForAvailable = (maxIndex, newFrameSteps) => {
    if (maxIndex === -Infinity) {
      const testFrameStepsIndexes = [];
      newFrameSteps.map((m, index) => {
        if (m.frame_type === 'test') {
          testFrameStepsIndexes.push(index)
        }
      })
      const minIndex = Math.min.apply(null, testFrameStepsIndexes)
      newFrameSteps.map((m, index) => {
        if (index <= minIndex) {
          m.isAvailable = true
        } else {
          m.isAvailable = false
        }
      })
    } else {
      newFrameSteps.map((frameStep, index) => {
        if (index <= maxIndex) {
          frameStep.isAvailable = true
        }
      })
    }
  }
  const changeColor = (frame_step) => {
    const newFrameSteps = frameSteps.slice();
    newFrameSteps.map((f) => {
      f.isColored = f.id === frame_step.id
      return f;
    })
    setFrameSteps(newFrameSteps)
    setCurrentFrameStep(frame_step)
  }
  const changeItem = (frame_step) => {
    if (frame_step.isAvailable) {
      changeColor(frame_step)
      setAnswers([])
      setSuccess(null)
    }
  }
  const changeAnswer = (answer) => {
    currentFrameStep.criterion.map((criteria) => {
      criteria.variants.map((m) => {
        if (m.key === answer.key) {
          m.choice = true;
        } else {
          m.choice = false;
        }
        return m;
      })
      return criteria;
    })
    setAnswers([answer])
  }
  const changeMultiChoiceAnswer = (answer) => {
    answer.choice = !answer.choice
    setAnswers(oldAnswer => [...oldAnswer, answer].filter((a) => a.choice === true));
  }
  const changeOpenEnded = (answer) => {
    setAnswers([answer])
  }
  const changeNextFrame = () => {
    const idx = frameSteps.findIndex(item => item.id === currentFrameStep.id)
    const nextFrame = frameSteps.find((frameStep, index) => index === (idx + 1))

    if (nextFrame) {
      changeItem(nextFrame)
    }
  }
  const onSubmit = (currentAnswer) => {
    let isSuccess = false;
    const successMessage = {text: lang.QUESTION.CONFIRMED, confirmed: true}
    const explanationMessage = {
      text: currentAnswer.explanation,
      confirmed: false
    }
    if (answers.length > 0) {
      if (currentAnswer.type === 'single_choice') {
        if (answers[0].key === currentAnswer.answer) {
          setSuccess(successMessage)
          isSuccess = true;
        } else {
          setSuccess(explanationMessage)
          isSuccess = false;
        }
      } else if (currentAnswer.type === 'multi_choice') {
        const ansewerKeys = [];
        answers.map(answer => ansewerKeys.push(answer.key))
        if (ansewerKeys.join() === currentAnswer.answer) {
          setSuccess(successMessage)
          isSuccess = true;
        } else {
          setSuccess(explanationMessage)
          isSuccess = false;
        }
      } else {
        console.log(currentAnswer)
        if (answers[0] === currentAnswer[0].label) {
          setSuccess(successMessage)
          isSuccess = true;
        } else {
          setSuccess(explanationMessage)
          isSuccess = false;
        }
      }
      const data = {
        user: user.id,
        id: currentFrameStep.id,
        answers: answers
      }
      isSuccess ? data.message = successMessage : data.message = explanationMessage;
      const newFrameSteps = frameSteps.slice();
      const maxIndex = newFrameSteps.findIndex((item) => item.id === currentFrameStep.id)
      defineAvailable(newFrameSteps, maxIndex)
      setFrameSteps(newFrameSteps)
      localStorage.setItem(`currentFrameStep-${currentFrameStep.id}`, JSON.stringify(data))
    }
  }
  const convertUrl = (text) => {
    if (text) {
      return text.replace('watch?v=', 'embed/')
    }
  }
  return (
    currentFrameStep ? (
      <div className="frame">
        <div className="frame-content">
          {currentFrameStep.frame_type === 'test' ? (
              <div className="frame-question">
                {currentFrameStep.criterion.map((criteria) => {
                  return (
                    <Fragment key={criteria.id}>
                      <h3
                        className="frame-question__title">{criteria.question}</h3>
                      {criteria.type === 'single_choice' ? (
                        <div className="frame-question-wrap">
                          {criteria.variants.map((answer, index) => {
                            return (
                              <Radio key={index} answer={answer}
                                     change={() => changeAnswer(answer)}/>
                            )
                          })}
                        </div>
                      ) : criteria.type === 'multi_choice' ? (
                          <div>
                            {criteria.variants.map((answer, index) => {
                              return (
                                <div key={index} className="test-inner">
                                  <Checkbox
                                    answer={answer}
                                    change={() => changeMultiChoiceAnswer(answer)}/>
                                </div>
                              )
                            })}
                          </div>
                        ) :
                        <div className="test">
                          <input onChange={(e) => changeOpenEnded(e.target.value)}
                                 className="open_ended" type="text"
                                 value={answers[0]}
                                 placeholder={lang.QUESTION.ANSWER_PLACEHOLDER}/>
                        </div>}
                      {
                        success ? (
                          <Fragment>
                            <div
                              className={`answer-${success.confirmed ? 'confirmed' : 'not-confirmed'}`}>
                              {success.text}
                            </div>
                            <button className="btn--action"
                                    onClick={() => changeNextFrame()}>{lang.NEXT}</button>
                          </Fragment>
                        ) : <button className="btn--action"
                                    onClick={() => onSubmit(criteria)}>{lang.QUESTION.ANSWER}</button>
                      }
                    </Fragment>
                  )
                })}
              </div>
            ) :
            <iframe src={convertUrl(currentFrameStep.video_url)}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
          }
        </div>
        <div className="frame-sidebar">
          {
            frameSteps.map((m) => {
              return (
                <div
                  key={m.id}
                  className={`frame-sidebar__item ${m.isAvailable ? '' : 'frame-sidebar__item--disabled'}`}
                  style={m.isColored ? {color: '#419098'} : {color: ''}}
                  onClick={() => changeItem(m)}>
                  {m.frame_type === 'test' ?
                    (<Fragment>
                      {m.isAvailable ?
                        <Icon name="question" color={m.isColored}/> :
                        <img src="/assets/icons/lock.svg" alt="lock"/>}
                      <span
                        className="desktop-block">{lang.QUESTION.TITLE}</span>
                    </Fragment>) :
                    (<Fragment>
                      {m.isAvailable ? <Icon name="play" color={m.isColored}/> :
                        <img src="/assets/icons/lock.svg" alt="lock"/>}
                      <span className="desktop-block">{lang.VIDEO}</span>
                    </Fragment>)}
                </div>
              )
            })
          }
        </div>
      </div>
    ) : ''
  )
}
export default Frame;
